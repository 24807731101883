.boxshadowinreply {
    height: 10vh;
    width: 98%;
    z-index: 40;
    display: flex;
    position: absolute;
    top: 67%;
    padding: 1%;
    background: #E6E6E6;
    margin-left: 1%;
    border-radius: 12px;
    align-items: center;
}

.replymaindivclass {
    height: 9vh;
    width: 100%;
    border-radius: 12px;
}

.replymaindivclassmini {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    background: #F4F4F4;
    height: 100%;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
}
.replymsgandusernameforimage {
    width: 80%;
    display: flex;
    align-items: center;
}
.replymsgandusername{
    width: 80%;
}
.replyimagediv {
    width: 15%;
}
.replyimagediv img {
    height: 45px;
    border-radius: 12px;
}
.replyusername {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
}



/* pdf reply main div chnages  */

.pdfreplydiv{
    height: 7vh;
    width: 31%;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 12px;
    align-items: center;

}
.pdflogo{
    height: 19px;
    margin-left: 2%;
    margin-right: 2%;
}
.replypdfname{
    width: 71%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;

color: #142B59;
}
.replydownladimage{
    height: 19px;
    margin-left: 1%;
    margin-right: 2%;
}
.pdfreplymaindivclass{
    height: 11vh;
    width: 100%;
    border-radius: 12px;
}
.pdfboxshadowinreply {
    height: 13vh;
    width: 98%;
    z-index: 40;
    display: flex;
    position: absolute;
    top: 64%;
    padding: 1%;
    background: #E6E6E6;
    margin-left: 1%;
    border-radius: 12px;
    align-items: center;
}