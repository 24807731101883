.addnewuserinquerybody{
    /* height: 40vh; */
    padding: 1rem;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.adduserdiv{
    /* position: relative;
    top: 9%; */
    display: flex;
    align-items: center;
    margin-top: 1%;
    /* left: 8%; */
    width: 80%;
    
    margin-left: 10%;

    background: #F0F0F0;
    border-radius: 10px;
    height: 6vh;
}
.adduserdiv img{
    height: 16px;
    margin-left: 8px;
    margin-right: 11px;
}
.usersinquery{

    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    width: 80%;

    margin-left: 10%;

    max-height: 20vh;
    margin-top: 4%;
    overflow-y: scroll;
}

.usersinquery::-webkit-scrollbar {display: none}
/* .showusernameandcheckbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0%;
    padding: 2%;
} */
.addnewuserbtn {
    /* position: relative; */
    /* top: 19%;
    left: 35%; */
    
    margin-left: 35%;

    background: #1B4D9F;
    border-radius: 30px;
    width: 22%;
    padding: 2px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 4%;
    line-height: 27px;
}