.inviteuserheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1B4D9F;
    height: 9vh;
    border-radius: 13.6318px 13.6318px 0px 0px;
}


.inviteuserheader span
{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    color: #FFFFFF;
padding-left: 35%;
}

.inviteusercrosss{
    background: #FFFFFF;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
}
.inviteuserbody{
    height: 22vh;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.inviteuserbody input{
    margin-top: 8%;
    margin-left: 7%;
    border-radius: 8px;
    height: 7vh;
    width: 85%;
    padding: 2%;
}

.inviteuserbody input::placeholder{
    /* padding-left: 3%; */
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16.3582px;
line-height: 20px;
/* identical to box height */


color: rgba(134, 134, 134, 0.5);
}

.modalsavebtn{
  
    background: #1B4D9F;
    border-radius: 20.4478px;
    width: 24%;
    height: 5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-top: 6%;
    margin-left: 35%;
    
}

.modalsavebtn span{
color :white
}