.adduserdropdown{
    margin-top: 0%;
    background: #F0F0F0;
    height: 7vh;
    display: flex;
    align-items: center;
    margin-right: 8%;
    margin-left: 7%;
    margin-top: 3%;
    border-radius: 8px;
}
.adduserdropdown img{
    height:12px;
    padding-left: 2%;
}
.addusermultiselector{
    width: 90%;
}
.adduserprojectnameinput{
    margin-top: 8%;
    margin-left: 7%;
    border-radius: 8px;
    height: 7vh;
    width: 85%;
    padding: 2%;
}
.adduserprojectbody{
    height: 41vh;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.searchWrapper{
    border: none !important;
    width: 23vw;
    overflow-x:scroll;
    white-space:nowrap;
    font-size: 10px;
    min-height: 50px;
}
.searchWrapper::-webkit-scrollbar {display:none}