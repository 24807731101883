.SlideScreen2div{
    margin-top: 3%;
    height: 100vh;
    width: 90%;
    background-color: 
    #F5F5F5;
    margin-left: 6%;
}
.spares2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}
.spares2 .spare2{
    background-color: white;
    border: 2px solid #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
.spares2 .activespare{
    background: #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
.slider2paragraphmain{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: center;
    color: #1B4D9F;
    position: absolute;
    left: 41%;
    top: 14%;
}

.slider2paragraph{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    color: #1B4D9F;
    width: 47%;
    position: relative;
    left: 26%;
    top: 12%;
}
.slider2paragraph2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    color: #1B4D9F;
    position: absolute;
    top: 38%;
    left: 31%;
    width: 39%;
}

.sliderbtn2{
    background: #1B4D9F;
    border-radius: 30px;
    border: none;
    width: 11%;
    position: absolute;
    top: 50%;
    left: 45%;
    height: 5vh;
}
.sliderbtn2 span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2rem;
    color: #ffffff;
}
.slider2img {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 57%;
    /* height: 5%; */
    left: 0%;
}
.slider2img img{
    height: 30vh;
}
