.addnewqueryheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1B4D9F;
    height: 9vh;
    border-radius: 13.6318px 13.6318px 0px 0px;
}
.addnewqueryheader span
{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    color: #FFFFFF;
padding-left: 31%;
}
.addnewquerycrosss{
    background: #FFFFFF;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
}
.addnewquerybody{
    /* height: 70vh; */
    padding: 5%;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.addnewqueryname{
    height: 6vh;
    width: 80%;
    background: #F0F0F0;
    border-radius: 10px;
    margin-left: 8%;
} 
.addnewqueryname img{
    height: 12px;
    margin-left: 9px;
    margin-right: 2px;}

.newqueryinput{
    width: 91%;
    height: 100%;
    padding: 2%;
}

.uploadtextinquery{
    height: 7vh;
    width: 80%;
    border: 1px solid #1B4D9F;
    border-radius: 10px;
    background: #F0F0F0;
    /* border: 1px solid #1B4D9F; */
    /* border-radius: 25px; */
    margin-left: 8%;
    margin-top: 4%;
}
.uploadtextinput{
    height: 100%;
    border-radius: 25px;
    width: 100%;
    padding: 5%;

}
.uploadtextinput::placeholder{
    /* padding-left: 5%; */
    color: #1B4D9F;
}
.uploadtextspan{
    display: flex;
   
    align-items: center;
}
.uploadtextspan img{
    height: 12px;
}
.uploadspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 2%;
    line-height: 24px;
    text-decoration-line: underline;
    color: #1B4D9F;
}
.adduserinquerydiv{
    /* position: relative;
    top: 18%; */
    display: flex;
    align-items: center;
    /* left: 8%; */
    margin-top: 4%;
    margin-left: 8%;
    width: 80%;
    background: #F5F5F5;
    /* border-radius: 10px; */
    height: 6vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid lightgray;
}
.adduserinquerydiv div{
    font-family: 'Aspira';
font-style: normal;
font-weight: 550;
font-size: 15px;
line-height: 29px;
color: #868686;
}
.adduserinquerydiv img{
    height: 16px;
    margin-left: 8px;
    margin-right: 11px;
}

.userslistinquery{
    background: #F5F5F5;
    /* box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2); */
    /* border-radius: 15px; */
    width: 80%;
    max-height: 20vh;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* margin-top: 4%; */
    margin-left: 8%;
}

.userslistinquery::-webkit-scrollbar {display: none}
.showusernameandcheckbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0%;
    padding: 2%;
}
.addnewusernameinquery{
    /* width: 85%; */
    margin-left: 10%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 29px;
/* identical to box height */
color: #1B4D9F;
}
.showusernameandcheckbox input{
    margin-right: 10%;
    border: 1px solid #1B4D9F;
}
.createnewquerybtn{
    background: #1B4D9F;
    border-radius: 30px;
    width: 24%;
    padding: 4px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    margin-top: 3%;
    margin-left: 35%;
}
.inputfileupload{
    margin-top: 3%;
    margin-left: 9%;

}
.uploadfileinput{
    position: absolute;
    opacity: 0;
    width: 40%;
    z-index: 10;
}
.uploadtextspanmini{
    width: 100%;
    display: flex;
    align-items: center;
}

.uploadedfilename{
    /* background: #F0F0F0; */
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #1B4D9F;;
    padding: 4px;
    width: 40%;
    display: flex;
    align-items: center;
}
.uploadedfilename div{
    /* width: 90%; */
    padding-left: 12px;
}
.cancleimagelogo{
    color:#1B4D9F;
    /* margin-left: 20%; */
}