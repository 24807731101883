.projectoptionmain{
    background: #F4F4F4;
box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
    width:20%
} */
/* .projectoptionpopover{
    width:120vw
} */
/* .PoPup1Sub{
    width: 72% ;
} */

.handleprojectoptionpopoverdiv{
    width: 200px;
}
.hrclass{
        margin: 0% ;
    color: none !important;
    border: 1px solid #8b8b8b1c  !important;
        background:#E2E2E2
} 
.projectheading{
    display: flex;
    align-items: center;
    height: 5vh;
    color: #142B59;
}
.projectheading:hover{
    color: white;
    background: #194895;
}

.editprojectspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    padding-left: 6%;
}
.CloseProject{
    display: flex;
    align-items: center;
    height: 5vh;
    color: #142B59;
}
.closeprojectspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    padding-left: 6%;
}
.CloseProject:hover{
    background: #194895;
    color: white;
}
.UserAccess{
    display: flex;
    align-items: center;
    height: 5vh;
    color: #142B59;
}
.UserAccess:hover{
    background: #194895;
    color: white;
}
.UserAccessspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;

    padding-left: 6%;
}

.ProjectUser{
    display: flex;
    align-items: center;
    height: 6vh;
    
}
.ProjectUser span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #142B59;
    padding-left: 6%;
}

.projectaddeduser{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
 .projectaddedusername{
    padding-left: 4%;
    padding-bottom: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #142B59;
    display: flr;
    display: flex;
    align-items: center;
    width: 90%;
    white-space: nowrap;
    overflow-x: scroll;
}
/* .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper css-1dmzujt{
    width: 13%;  
} */
/* .css-1w87dxg{
    width: 49% !important; 
} */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1dmzujt {
    width: 220px !important;
}
.projectaddedusername::-webkit-scrollbar {display: none;}
.adduser{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #142B59;
    padding-left: 8%;
    height: 5vh;
    cursor: pointer;
}
.adduser img{
    height: 18px;
    margin-right: 3%;
}
.projectoptionuserlogo{
    width: 19%;
}
.projectoptionuserName{
    /* margin-left: 6%; */
    width: 66%;
    /* max-width: 66%; */
    overflow-x: scroll;
    white-space: nowrap;
}
.projectoptionuserName::-webkit-scrollbar {display:none;}