.messagesend {
    display: flex;
    /* align-items: center; */
    justify-content: end;
    float: right;
    width: 52%;
    /* background: purple; */
    margin-top: 2%;
}

.usermsgdiv {
    margin-right: 2%;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: end;
    flex-direction: column;
}

.loginusernametime {
    color: #142B59;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    margin-left: 3%;
    margin-bottom: 0.5vh;
    /* padding-left: 70%; */
}

.loginusernametime span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #868686;
}

.usernamelogo {
    height: 30px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 5%;
    margin-right: 1%;
}