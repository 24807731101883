.sidebardrawer {
    box-shadow: none;
    border: none;
    position: relative;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    border: none;
    background-color: #194895;
    border-radius: 0px 29px 0px 0px;
    
}
.css-1l8j5k8{
    background-color: #194895 !important;
}
.css-xfieiy .MuiDrawer-paper{
    border: none !important;
    border-top-right-radius: 25px;
}
ul.MuiList-root.MuiList-padding.css-1qjeqw0 {
    position: relative;
    top: 10%;
}
.ListItemclasss {
    background-color: white;
    position: relative;
    z-index: 10;

}

.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root{
    color:"white"
}
.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root span{
    color :"white"
}




/* sidebar upper logo portion */

.isverifyemailsidebarlogodiv{
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    height: 5vh;
    position: relative;
    top: 10%;
}

.sidebarlogodiv{
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    height: 5vh;
    position: relative;
    top: 3%;
}

.sidebarlogobtn{
    background: transparent;
    border: none;
    width: auto;
}
.sidebarbtnlogo{
    height: 31px;
}
.QMSspan{
    color: white;
    font-style: normal;
    font-weight: 600;
    /* font-size: .9rem; */
    font-size: 15px;
    line-height: 39px;
    margin-bottom: 1%;
    margin-right: 11%;
}
.sidebarlogomenu{
    width: 15%;
    border: none;
    background: transparent;
    margin-right: 5%;
}
.sidebarbtnmenu{
    height: 15px;
}




/* close */


.sidebarlogodivclose{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    height: 5vh;
    position: relative;
    width: 100%;
    top: 3%;
    /* left: 9%; */
margin-left: 15%;
}

.isverifyemailsidebarlogodivclose{
    height: 5vh;
    position: relative;
    width: 100%;
    top: 6%;
    /* left: 9%; */
margin-left: 15%;
}

.sidebarlogomenuclose{
    width: 15%;
    width: 44%;
    border: none;
    background: transparent;
   /* margin-right: 20%; */
    /* margin-right: 5%; */

}
/* close */

/* side bar upper logo portion end */




/* side bar option list */

.MuiList-root.MuiList-padding.css-1w084vs-MuiList-root{
    margin-top: 60%;
}

.ullist{
    width: 100%;
    height: 4vh;
}
.list{
list-style: none;
display: flex;
width: 100%;
margin-bottom: 19%;
}

.sidebaroptionbtndiv{
    margin-left: 4%;
    color: black;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    width: 11vw;

}
.sidebaroption{
    display: flex;
    position: absolute;
    top: 20%;
    height: 5vh;
    width: 100%;
}
.sidebaroptionimagediv{
    background-color: #FFFFFF;
    width: 45px !important;
    height: 34px !important;
    margin-left: 7% !important;
    margin-right: 2% !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
   
}
.sidebaroptionimagediv1{
    background-color: #FFFFFF !important;
    width: 45px !important;
    height: 34px !important;
    margin-left: 7% !important;
    margin-right: 2% !important;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebaroptionimage1{
    height: 20px;
    padding:1%;
}
.sidebaroptionimage{
    height: 24px;
    padding:1px;
}
.sidebaroptionbtndiv{

display: flex;
position: relative;
align-items: center;
width: 100%;
background-color: white;
border-radius: 9999px 100px 100px 9999px;
}
.sidebaroptionbtndiv1{   
    display: flex;
    align-items: center;
    width: 100%;
    }


    /* list for close */
    .listclose{
        list-style: none;
        /* display: flex; */
        width: 100%;
        margin-bottom: 6vh;
        margin-left: 13%;
    }


    .sidebaroptionimagedivclose{
        background-color: white;
        width: 35px;
        height: 35px;
        margin-left: 3%;
        margin-right: 5%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

    }
    .sidebaroptionimagedivclose.sidebaroptionimageclose{
        height: 14px;
        
    }
    
    .sidebaradduserbtnclose img{
        height: 17px;

    }
    .sidebaradduserbtnclose{
        position: absolute;
    top: 86%;
    background: #FFFFFF;
    left: 22%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
/* side bar option list */


/* addd user btn */
.sidebaradduserbtn{
    position: absolute;
    top: 88%;
    left: 21%;
    background-color: #FFFFFF;
    /* height: 5vh; */
    /* width: 8vw; */
    cursor: pointer;
    border-radius: 1rem;
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
}
.sidebaradduserbtn span{
font-style: normal;
font-weight: 600;
font-size: .9rem;
padding: 6px;
color: #194895;

}
/*  add user btn */

 .sidebaroptionspandiv::before{
    content: "";
    position: absolute;
    top: -30px;
    right: 0%;
    width: 30px;
    height: 30px;
    /* height: 90%; */
    background-color: #194895;
    z-index: -1;
    box-shadow: 7px 7px 0 6px white;
    border-bottom-right-radius: 100%
 }

.sidebaroptionspandiv::after{
    content: "";
    position: absolute;
    bottom: -30px;
    right: 0%;
    width: 30px;
    height: 30px;
    background-color: #194895;
    border-top-right-radius: 100%;
    z-index: -1;
    box-shadow: 7px -7px 0 6px white;
}
.sidebaroptionspandiv{
    cursor: pointer;
}

.sidebaroptionspandiv1{
    cursor: pointer
}




/* verify email red line */
.isverifyemail{
    position: absolute;
    top: 0;
    background: #E22929;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 29px;
    
    color: #FFFFFF;
    width: 100%;
    display: flex;
    z-index: 99974;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
}



