
.addfileheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1B4D9F;
    height: 9vh;
    border-radius: 13.6318px 13.6318px 0px 0px;
}
.addfileheader span
{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    color: #FFFFFF;
padding-left: 35%;
}
.addfilecrosss{
    background: #FFFFFF;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
}
.addfilebody{

    padding: 4%;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.selectsequencediv{
    display: flex;
    align-items: center;
    width: 96%;
    justify-content: space-between;
    background: #F0F0F0;
    height: 7vh;
    padding: 2%;
    border-radius: 10px;
}
.sequencelogoandname{
display: flex;
align-items: center;
width: 80%;
}
.selectsequencediv span{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 27px;
margin-left: 3%;
color: #868686;
}
.sequencelogoandname input{
margin-left: 3%;
}


.sequencelogoandname img{
    height: 20px;
}

.uploadsequencediv{
    display: flex;
    align-items: center;
    width: 96%;
    justify-content: space-between;
    background: #F0F0F0;
    height: 7vh;
    padding: 2%;
    margin-top: 2%;
    border-radius: 10px;
}
.uploadfilelogoandname{
    display: flex;
align-items: center;
width: 80%;
}
.uploadfilelogoandname div{
    margin-left: 6%;
    font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  
  color: #868686;
  }


  .isupload{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 19px;
color: #1B4D9F;

}
.sequencefiletouploaded{
    max-height: 16vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    position: absolute;
    top: 52%;
    width: 90%;
    z-index: 20;
    margin-left: 3%;
    padding: 2%;
    overflow-y: scroll;
}
.sequencefiletouploaded::-webkit-scrollbar {
    width: .4rem;
  }
  
  .sequencefiletouploaded::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    margin-block: 2rem;
  }
  
  .sequencefiletouploaded::-webkit-scrollbar-thumb {

    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
.sequencelistforfile{
    margin-bottom: 2%;
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    height: 4vh;
}
.sequencelistforfile div{
    margin-left:4%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 24px;

color: #142B59;
}
.isuploadfalse{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 19px;
color: #868686;
}
  .uploadsequencefile{
    position: absolute;
    top: 57;
    opacity: 0;
    width: 49%;
    z-index: 10;
  }


    .addfilesavebtn{
  
        background: #B7B7B7;
        border-radius: 30px;
        width: 24%;
        height: 5vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        margin-top: 6%;
        margin-left: 35%;
        color: #FFFFFF;
    }
  