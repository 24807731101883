.changepasswordbody{
    /* height:45vh; */
    padding-bottom: 1%;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.changepassworddiv{
    height: 8vh;
    width: 87%;
    margin-left: 7%;
    padding-top: 3vh;
    /* margin-top: 5%; */
    /* background: #F0F0F0; */

}
.changepasswordnameinput:focus{
    background:  #F0F0F0;
}
.changepassworddiv2{
    height: 8vh;
    width: 87%;
    margin-left: 7%;
    padding-top: 3vh;
    /* margin-top: 5%; */

}
.changepasswordsavebtn{
    margin-left: 30%;
    display: flex;
    background-color: #1B4D9F;
    border: none;
    border-radius: 1rem;
    margin-top: 4%;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-bottom: 2%;
}
.changepasswordsavebtn span{
padding: 4%;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 1rem;
line-height: 27px;

color: #FFFFFF;
}
.changepasswordemailspan{
    background: #F0F0F0;
    border: none;
}
.changepasswordnameinput{
    background: #F0F0F0;
    border: none;
}
.changepasswordparagraph{
    margin-top: 1%;
    font-size: x-small;
    margin-left: 9%;
    color: red;
}