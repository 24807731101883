.verifyemailmaindiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}
.emailbtn{
    border: none;
    padding: 9px;
    border-radius: 11px;
    background: gray;
    color: white;
}
.pendingdiv{
    position: relative;
    /* top: 0; */
    height: 100vh;
    width: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.pendingdiv img{
    height:51vh
}
.pendingstatus{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 42px;
    color: #142B59;
    margin-top: 1%;
}
.pendingheader{
    position: absolute;
    top: 0;
    height: 5%;
    width: 100%;
    /* background: red; */
    background: #FEDA8E;
border-radius: 0px 0px 20px 20px;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
}
.pendingspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    text-align: center;
    color: #656565;
}
.pendingverify{
    
font-family: 'Inter';
font-style: normal;
font-weight: 700;
    font-size: 15px;
    line-height: 31px;
    background: #1B4D9F;
    border-radius: 30px;
    width: 9%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
}

/* success */
.successdiv{
    position: relative;
    /* top: 0; */
    height: 100vh;
    width: 100%;  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.successdiv img{
    height:70%;
    width:100%

}
.successstatus{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 42px;
    color: #142B59;
    margin-top: 1%;
}
.successheader{
    position: absolute;
    top: 0;
    height: 5%;
    width: 100%;

    background: #63B32E;
border-radius: 0px 0px 20px 20px;
}
.successspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    text-align: center;
    color: #656565;
}
.successbtn{
    
font-family: 'Inter';
font-style: normal;
font-weight: 700;
    font-size: 15px;
    line-height: 31px;
    background: #1B4D9F;
    border-radius: 30px;
    width: 9%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
}