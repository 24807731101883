.SlideScreen3div{
    margin-top: 3%;
    height: 100vh;
    width: 90%;
    background-color: 
    #F5F5F5;
    margin-left: 6%;
}
.spares3{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}
.spares3 .spare3{
    background-color: white;
    border: 2px solid #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
.spares3 .activespare3{
    background: #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
.slider3paragraphmain{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: center;
    color: #1B4D9F;
    position: absolute;
    left: 42%;
    top: 16%;
}

.slider3paragraph{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    color: #1B4D9F;
    width: 47%;
    position: relative;
    left: 28%;
    top: 15%;
}

.sliderbtn3{
    background: #1B4D9F;
    border-radius: 30px;
    border: none;
    width: 17%;
    position: absolute;
    top: 38%;
    left: 42%;
    height: 5vh;
}
.sliderbtn3 span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2rem;
    color: #ffffff;
}
.slider3img {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    /* height: 5%; */
    left: 0%;
}
.slider3img img{
    height: 37vh;
}

.inviteuseruparrow{
    position: absolute;
    left: 77%;
}
.inviteuseruparrow img{
    height:14vh
}
.inviteuserdownarrow{
    position: absolute;
    top: 55%;
    left: 11%;
}
.inviteuserdownarrow img{
    height: 14vh;
}