
    .ispdftruemaindiv {
        background: #E3E3E3;
        border-radius: 12px;
        width: 74%;
        height: 55px;
        padding-top: 2px;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 0px;
    }

    .pdfimage{
        /* height: 29px; */
        width: 11%;
        margin-left: 6%;
        margin-right: 2%;
    }
    .pdfFileandreplylogodiv{
        display: flex;
        align-items: center;
        margin-top: 1%;
        /* width: 100%; */
    }
.downladimage{
    height: 22px;
    margin-right: 6%;
    margin-left: 2%;
}
.recievedpdfspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    width: 80%;
    line-height: 22px;
    color: #142B59;
}
.ispdftrue {
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 12px;
    max-width: 100%;
    height: 8vh;
    color: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.msgandreplydiv{
    display: flex;
    align-items: center;
    margin-top: 1%;
    /* justify-content: center; */
}
.messagethatreply{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    color: #868686;
}
.recievedmessagereply{
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.recievedmesssagepdfreply{
    display: flex;
    align-items: center;
    margin-top: 1%;
    width: 68%;
}
.imagewithspanmaindiv{
    display: flex;
    align-items: center;
    margin-top: 1%;
    width: 73%;
}
.replyofamessagemaindiv{

    padding: 1px;
    width: auto;
    border-radius: 0px 12px 12px 12px;
}
.replyto{
    background-color: #F4F4F4;
    border-radius: 0px 12px 12px 12px;
    display: flex;
    height: 8vh;
    justify-content: center;
    flex-direction: column;
    padding-left: 16px;
}

.replytopdf{
    background-color: #F4F4F4;
    border-radius: 0px 12px 12px 12px;
    display: flex;
    height: 8vh;
    justify-content: center;
    align-items: center;
}

.replytoimage{
    background-color: #F4F4F4;
    border-radius: 0px 12px 12px 12px;
    display: flex;
    height: 8vh;
    /* justify-content: center; */
    align-items: center;
}
.replytologo{
    height: 12px;
    width: 18px;
}
.replytoimageusernameandlogo{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.replytoimageusernameandlogo span{
    font-size: 13px;
    color: #868686;
    font-weight: 500;
}
.imagewithtextreplyspan{
    font-size: 12px;
    color: #868686;
}
.replytorealimage{
    height: 25px;
    margin-right: 10px;
    margin-left: 5px;
}
.replytousername{
    font-size: 12px;
    font-weight: 500;
}
.messagerecievedimage{
    width: 73%;
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.recievedmsgdiv {
    /* max-width: 70%; */
    width: auto;
    position: relative;
    display: inline-block;
    padding: 0.6rem 1rem;
    border-radius: 0px 12px 12px 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    line-height: 22px;
    /* display: flex; */
    color: #FFFFFF;
    font-size: 13px;
}
.imagerecieved{
    width: 100%;
    height: 22vh;
    border-radius: 0px 12px 12px 12px;
    background: red;
    padding: 1%;
}
.imagerecieved img{
    height: 100%;
    width: 100%;
    border-radius: 0px 12px 12px 12px;
}

.imagewithspan img{
    border-radius: 0px 12px 12px 12px;
    width: 100%;
}