.contactusheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1B4D9F;
    height: 9vh;
    border-radius: 13.6318px 13.6318px 0px 0px;
}


.contactusheader span
{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    color: #FFFFFF;
padding-left: 35%;
}
.contactcrosss{
    background: #FFFFFF;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
    cursor: pointer;
}
.contactbody{
    height: 28vh;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.contacttextarea{
    height: 14vh;
    width: 75%;
    border: none;
    background: #F0F0F0;
    border-radius: 10px;
    padding: 3%;
}
.contacttextarea:focus-visible{
  border: none;
  outline: none;
}
.contactsavebtn{
    width: 20%;
    background: #194895;
    margin-top: 4%;
    height: 5vh;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}