
.webhomemaindiv{
    display: flex;
    width: 100%;
    background: #FFFFFF;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.webhomeleftdiv{
    width: 55%;
background: #FFFFFF;
display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: column;
}
.webhomerightdiv{
    width: 45%;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

}


.newlogoimage img{
height: 18vh;
margin-left: 4%;
}
.webhomepagespan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 5rem;
    width: 67%;
    margin-left: 11%;
    margin-top: 7%;
    line-height: 62px;
    color: #1B4D9F;

}
.smallspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 24px;
    color: #292929;
    margin-top: 2%;
    margin-left: 6%;
}
.webhomeinputandbtn{
    display: flex;
    align-items: cener;
    align-items: center;
    width: 80%;
    margin-left: 11%;
    margin-top: 7%;
}
.webhomeinputgmail{
    height: 6vh;
    width:50%;
border-radius: 11px;
background: rgba(237, 237, 237, 0.8);
}
.webhomeinputgmail::placeholder{
    padding-left: 5%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16.9982px;
line-height: 21px;



color: rgba(33, 33, 33, 0.6);
}
.webhomeletsstartbtn{
    background: #1B4D9F;
    border-radius: 10px;

    cursor: pointer;
    width: 34%;
    display: flex;
    padding: 11px;
    align-items: center;
    justify-content: center;
  
    
}
.webhomeletsstartbtn span{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;

color: #FFFFFF;
}