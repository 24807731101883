.addusermaindiv{
    width: 94%;

    margin-left: 4%;
    height: 87vh;
    margin-top: 2%;

}
.adduserheadingdiv{
height: 10vh;
display: flex;
align-items: center;
justify-content: space-between;
}
.searchuser{
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.searchuserinput{
    height: 50%;
    width: 80%;
    background: #E3E3E3;
border-radius: 16.6661px;
}
.searchuserinput::placeholder{
    padding-left: 4%;
}
.adduserprojectname{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
line-height: 48px;

color: #142B59;
}
.adduserheading{

    display: flex;
    align-items: center;

}
.userstableData{
    height: 65vh;
margin-top: 1%;
    overflow-y: scroll;
}
.userstableData::-webkit-scrollbar {
    display: none;
}
.addusercheckbox{
    width: 10%; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.adduseremail{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 1.1rem;
line-height: 24px;
color: #000000;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.addusername{
    width: 20%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.addusercompany{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    
    color: #000000;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.adduserstatus{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    
    color: #000000;
    width: 10%;
    display: flex;
    align-items: center;
 
}

.adduserdata{
    display: flex;
    align-items: center;
    margin-top: 1%;
    height: 6vh;
    /* background: #EDEDED; */
    background: #F1F1F1;
/* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2); */
border-radius: 10px;
}
.addusercheckboxdata{
    width: 10%; 
   
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.adduseremaildata{

    width: 20%;

    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 24px;

color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    overflow-x: scroll;
}
.adduseremaildata::-webkit-scrollbar {
    display: none;
}
.addusernamedata{
    width: 20%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    
    color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.addusercompanydata{

    width: 20%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    
    color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.adduserstatusdatablue{
    width: 10%;
 
    border-radius: 16.9243px;
    font-family: 'Inter';
    font-style: normal;
    padding: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #194895;
    display: flex;
    align-items: center;

}
.adduserstatusdata{

    width: 10%;
 
    border-radius: 16.9243px;
    font-family: 'Inter';
    font-style: normal;
    padding: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: green;
    display: flex;
    align-items: center;
  
}

.addusercheckboxdatainput{
    border: none;
}



.unselecteddata{
    display: flex;
    align-items: center;
    margin-top: 1%;
    height: 6vh;
    background: #F7FAFF;

/* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2); */
border-radius: 10px;
}
.unselectedcheckboxdata{
    width: 10%; 

    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unselectedemaildata{
 
    width: 20%;
    color: black;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1rem;
line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    overflow-x: scroll;
}
.unselectedemaildata::-webkit-scrollbar {
    display: none;
}
.unselectednamedata{
    width: 20%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.unselectedcompanydata{

    width: 20%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}
.unselectedstatusdata{
 
    width: 10%;

    border-radius: 16.9243px;
    font-family: 'Inter';
    font-style: normal;
    padding: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #EF8F1D;
    display: flex;
    align-items: center;

}


.savechanges{
    background: #1B4D9F;
    border-radius: 15.2514px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-top: 1%;
    float: right;
    margin-right: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}