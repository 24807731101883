.nothingmaindiv{
    width: 93%;
    height: 91vh;
    background: #F5F5F5;
    margin-left: 4%;
    margin-top: 2%;
}
.nothingimage{
    height:50%;
    position: absolute;
    top: 10%;
    left: 35%;
}
.nothingspan{
    position: absolute;
    top: 64%;
    left: 42%;
    color: gray;
}