

.addprojectmaindiv{
  color: black;
  width: 20%;
  /* margin-left: 9%; */
}
.addprojectdropdown{
  background:#F0F0F0;
  display:flex;
  border-radius: 0.325rem;
  align-items:center;
  margin-top: 4vh;
}
.addprojectdropdown img{
  margin-left: 2%;
  height:3vh
}
.option{
  color: black;
}
.optionContainer .option:hover{
  color: white;
  background:#1b4d9f;
}

.notFound{
  color: black;
  font-size: 1rem !important;
  font-weight: 600;
}
.optionListContainer{
    background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  box-shadow: 1px 1px 10px gray;
  /* background: #FFFFFF;
box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
border-radius: 10px; */
}