.reportchatreddots{
    height: 6px;
    width: 6px;
    background: #FFFFFF;
    border-radius: 50%;
    position: relative;
    left: 0;
}
.reportlistofdata{

}
.reportul{
    padding-left:0% !important;
    margin-bottom: 0% !important;
}
.reportinnerlist{
    display: flex;
    align-items: center;
}
.reportdataname{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 6%;
}