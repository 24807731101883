.editprojectnameheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1B4D9F;
    height: 9vh;
    border-radius: 13.6318px 13.6318px 0px 0px;
}


.editprojectnameheader span
{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 26px;
    color: #FFFFFF;
padding-left: 35%;
}

.editprojectcrosss{
    background: #FFFFFF;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
    cursor: pointer;
}
.editprojectbody{
    height: 25vh;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
}
.editprojectbody input{
    margin-top: 10%;
    margin-left: 7%;
    border-radius: 8px;
    height: 7vh;
    padding: 2%;
    width: 85%;
}

.editprojectbody input::placeholder{
    /* padding-left: 3%; */
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16.3582px;
line-height: 20px;
/* identical to box height */


color: rgba(134, 134, 134, 0.5);
}

.modalprojectsavebtn{
 
    background: #1B4D9F;
    border-radius: 20.4478px;
    width: 24%;
    height: 5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-top: 4%;
    margin-left: 35%;
    
}

.modalprojectsavebtn span{
color :white
}