.storage{
    height: 100vh;
    margin-top: 4%;
    /* background: red; */
    width: 94%;
    margin-left: 4%;
    display: flex;
}
.storageproject{
    width:30%
}
.storagedescription{
    width: 70%;
}
.projectsequencestorage{
    height: auto;
    width: 95%;
    /* max-height: 30%; */
    /* overflow-y: scroll; */
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin-top: 2%;
    margin-left: 2.5%;
    padding: 1%;
}
.projectStoragefile{
    /* box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2); */
    /* background: #FFFFFF; */
    /* padding: 4px; */
    /* border-radius: 23px; */
    width: 96%;
    /* margin-top: 2%; */
    overflow-y: scroll;
    max-height:32vh;
    margin-left: 2%;

}
.projectStoragefile::-webkit-scrollbar {
    width: .4rem;
  }
  
  .projectStoragefile::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    /* margin-block: 14rem; */
  }
  
  .projectStoragefile::-webkit-scrollbar-thumb {
    /* background-color: rgb(0, 207, 137); */
    /* background: url("/scroll-thumb.svg") center bottom no-repeat; */
    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
.projectStoragefilemaindiv{
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    background: #FFFFFF;
    padding: 4px;
    border-radius: 23px;
    width: 96%;
    margin-top: 2%;
    /* overflow-y: scroll; */
    max-height: 38vh;
    margin-left: 2%;
}
/* .projectStoragefile::-webkit-scrollbar {display: none;} */
.upgradeplan{
    width: 96%;
    /* background: red; */
    margin-left: 2%;
    height: 10vh;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    color: black;
}
.upgradeplanbtn{
    margin-left: 42%;
    background: #193485;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    color: white;
    font-size: 12px;
    padding: 12px;
}
.upgradeprojectstorage{
    /* background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2); */
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 4%; */
    height: 45%;
}
.imagedivstorage{
    height: 8vh;
    width:25%;

background: #FFFFFF;
box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
border-radius: 15px;
display: flex;
align-items: center;
justify-content: center;
}
.imagedivstorage img{
    height:30px
}
.projectsquarestorage{
    height: 25%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.projectclosequerysquarestorage{
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    /* margin-right: 4%; */
    height: 61%;
}
.fileupload img{
    height: 18px;
}
.uploadfilestorage{
    font-family: 'Inter';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    width: 88%;
    margin-left: 5%;
    margin-top: 2%;
    /* background: red; */
    font-size: 15px;
    line-height: 18px;

}
.usedstorage{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

}
.uploaddatainstorage{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #D3E4FF;
}
.uploadscreen{
    display: flex;
    align-items: center;
    /* justify-content: centewr; */
    justify-content: center;
    flex-direction: column;
    height: 91%;
    color: black;
}
.uploadscreen img{
    height:40%
}
.uploadscreenspan{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 135%;
/* or 32px */

text-align: center;

color: #7E7E7E;
}
.uploadbtn{
 
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        background: #1B4D9F;
        border-radius: 30px;
        color: #FFFFFF;
        width: 16%;
        padding: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
        cursor: pointer;
}

.storagebar{
    background: white;
    width: 87%;
    height: 1vh;
    margin-left: 6%;
    margin-top: 4%;
    border-radius: 10px;
    position: relative;
}
.storageprogressbar{
    position: absolute;
    background: #26CF18;
    /* border-radius: 5px 0px 0px 5px; */
    height: 1vh;
    border-radius: 10px;
}

.storageinputupload{
    position: absolute;
    top: 73%;
    width: 14%;
    opacity: 0;
    z-index: 10;
    /* cursor: pointer; */
}
.addmorefilebtn{
    color: #1B4D9F;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 4%;
    display: flex;
    margin-top: 2%;
    /* justify-content: center; */
}
.addmorefilebtn img{
    height: 21px; 
    margin-top: 2px;

}
.totalfree{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 27px;

color: #22BC15;
}
.sequencefilestoragescroll{
overflow-y: scroll;
max-height: 20vh;
}
.sequencenamelist{
    display: flex;
    width: 61%;
    align-items: center;
}
.sequencelistscrollinstorage{
    height: 22vh;
    /* overflow-x: scroll; */
    overflow-y: scroll;
}
.sequencelistscrollinstorage::-webkit-scrollbar {
    width: .4rem;
  }
  
  .sequencelistscrollinstorage::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    margin-block: 14rem;
  }
  
  .sequencelistscrollinstorage::-webkit-scrollbar-thumb {
    /* background-color: rgb(0, 207, 137); */
    /* background: url("/scroll-thumb.svg") center bottom no-repeat; */
    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
/* .userlistname{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;

color: #142B59;
} */




/* here is the only part of storage description  */

.sequnecestoragelist{
    
    margin-left: 2%;
    margin-top: 2%;

}
.listofsequencename{
    display: flex;
    align-items: center;
}
.listofsequencename .name{
    padding-left: 0%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* width: 90%; */
    color: #142B59;
    display: flex;
    padding-left: 5%;
}

/* end of storage profile description */