
.sendmessagediv {
    /* max-width: 80%; */
    width: auto;
    position: relative;
    /* margin-left: 68%; */
    display: inline-block;
    padding: 0.6rem 1rem;
    background: #9228C4;
    border-radius: 12px 0px 12px 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    line-height: 22px;
    color: #FFFFFF;
}
.selfmsgandreplydiv{
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
   margin-top: 1%;
    width: 100%;
}

.selfpdfmaindiv{
    background: #E3E3E3;
    border-radius: 12px;
    /* width: 70%; */
    /* height: 15vh; */
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}
.sendispdftrue{
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 12px;
    max-width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.selfpdfspan{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
width: 80%;
line-height: 22px;
color: #142B59;
}

.sendpdfimage{
    width: 11%;
    margin-left: 6%;
    margin-right: 2%;
}
.selfpdfreply{
    display: flex;
    align-items: center;
    width: 70%;
    margin-top: 1%;
}
.sendreplyofamessagemaindiv{
background: pink;
    padding: 1px;
    width: auto;
    border-radius: 12px 0px 12px 12px;
}
.selfsendreplyto{
    background-color: #F4F4F4;
    border-radius: 12px 0px 12px 12px;
    display: flex;
    height: 8vh;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    padding-left: 16px;
}
.sendreplytousername{
    font-size: 12px;
    font-weight: 500;
}
.selfchatreplydiv{
    color: gray;
    margin-right: 2%;
}
.chatselfreplymaindiv{
    display: flex;
    align-items: center;
    margin-top: 1%;
}


.sendimagewithspanmaindiv{
    display: flex;
    align-items: center;
    margin-top: 1%;
    width: 70%;
}
.sendmessagethatreply{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    color: #868686;
}

.sendimagewithspan img{
    width: 100%;
    border-radius: 12px 0px 12px 12px;
}

.sendmessageimagemaindiv{

        display: flex;
        height: 23vh;
        width: 62%;
        align-items: center;
    }
    
.sendmessageimage{
    height: 100%;
    background: red;
    width: 100%;
    padding: 1%;
    border-radius: 12px 0px 12px 12px;
}
.sendmessageimage img{
    height: 100%;
    width: 100%;
    border-radius: 12px 0px 12px 12px;
}
.selfreplytopdf{
    background-color: #F4F4F4;
    border-radius: 12px 0px 12px 12px;
    display: flex;
    height: 8vh;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    /* padding-left: 16px; */
}

.selfreplytoimage{
    background-color: #F4F4F4;
    border-radius: 12px 0px 12px 12px;
    display: flex;
    height: 8vh;
    align-items: center;
    /* flex-direction: column; */
    /* padding-left: 16px; */
}
.selfreplytorealimage{
    height: 20px;
    margin-left: 4px;
    margin-right: 6px;
}
.sendreplytoimageusernameandlogo{
    display: flex;
    flex-direction: column;
}
.replytopdfusername{
    font-size: 12px;
    color: gray;
}
.pdfreplylogo{
    height: 25px;
    margin-left: 2%;
    margin-right: 2%;
}

.replytopdfname{
    font-size: 12px;
    color: gray;
}
.sendreplytoimageusernameandlogo img{
    height: 12px;
    width: 16px;
}
.sendreplytoimageusernameandlogo span{
    font-size: 12px;
    color: #868686;
}

.pdfchatselfreplymaindiv{
    display: flex;
    align-items: center;
    margin-top: 1%;
    width: 60%;
}