.chatinfomaindiv{
    width: 65%;
    height: 100vh;
    border-radius: 1rem;
    margin-top: 2%;
    background: #F4F4F4;
    position: relative;
}

.chatInfoheader img{
    height: 3vh;
    padding-left: 2%;
}
.chatInfoheader {
    height: 9vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: #194895;
    display: flex;
    align-items: center;
    width: 100%;
  
}

.chatinfoback{
    height: 5vh;
    width: 1.2rem;
    margin-left: 2%;
}
.chatInfoheader span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
    padding-left: 1%;
    width: 100%;
}

.chatinfoheaderimagespandiv {
    width: 15%;
}

.chatinfoheaderimagespandiv img {
    height: 21px;
    padding-bottom: 4%;
}
.chatcreatedinfo{
    display: flex;
    flex-direction: column;
    padding-left: 0%;
    /* height: 12vh; */
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
   
}

.querychatinfo{
    display: flex;
    align-items: center;
    padding-left: 7%;
}
.querychatinfo img{
 height: 13px;
    margin-top: .6%;
}
.querychatinfo span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    color: #142B59;
    margin-left: 1%;
}
.querycreatedby{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    padding-left: 7%;
    color: #7E7E7E;
    padding-top: .5%;
}
.chatinfohr{
    border: 1px solid #8080806b;
    margin-top: 0%;
    margin-bottom: 0%;
    width: 86%;
    margin-left: 7%;
}
.chatinfototaluser {
    margin-left: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.chatinfototaluser img{
    height: 2vh;
    /* margin-bottom: .7%; */
}
.chatinfototaluser span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    color: #142B59;
    margin-left: 1%;
}
.listofuser{
    display: flex;
    margin-left: 7%;
    margin-bottom: 1%;
    margin-top: 1%;
    align-items: center;
    justify-content: space-between;
}
.chatinfouserbtn{
    color:#194895;
    margin-right: 7%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 24px;
}
.listofadminandsimpleuser{
    display: flex;
width: 40%;
    align-items: center;
}
.logofirstletter{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: white;
    background: blue;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.userlistname{
    padding-left: 0%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    width: 90%;
    color: #142B59;
    display: flex;
    padding-left: 5%;
}
.userlistname span{
    padding-left: 1%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color:#8E8E8E
    ;
}

.exportchat{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 87%;
    margin-left: 7%;
    margin-top: 1%;
    margin-bottom: 1%;
}
.exportchatdiv{
    display: flex;
    align-items: center;
   
    width: 70%;
}
.exportchatdiv div{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    color: #142B59;
}
.exportchatdiv div span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #8E8E8E;
} 
.exportchatdiv img{
    height: 15px;
    padding-right: 2%;
}
.exportchatrightarrow{
    height: 4vh;
    width: 4%;
    color: #142B59
}
.chatqueryusers{
    display: flex;
    margin-left: 7%;
    margin-top: 1%;
    align-items: center;
}
.chatqueryusers img{
    height:12px;
    margin-right: 1%;
}
.chatqueryusers span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 27px;
    color: #142B59;
}
.closechat{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    margin-left: 6%;
    margin-top: 1%;
    margin-bottom: 1%;
}
.closechatdiv{
    display: flex;
    align-items: center;
    width: 100%;
}
.crossclosechat{
    color: #DE4545;
    height: 22px;
    width: 2vw;
}
.closechatdiv  div{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: red;
    font-size: 15px;
}
.closechatdiv div span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #8E8E8E;
} 

/*   all user scrollable list*/
.alluserinquerylist{
    height: auto;
    max-height: 25%;
    overflow-y: scroll;
}
.alluserinquerylist::-webkit-scrollbar {
    width: .4rem;
  }
  
  .alluserinquerylist::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    margin-block: 14rem;
  }
  
  .alluserinquerylist::-webkit-scrollbar-thumb {
 
    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
  /* End all user scrollable list*/

  .adduserinquery{
    display: flex;
    margin-top: 1%;
    margin-left: 7%;
    margin-bottom: 1%;
  }
  .adduserinquery img{
    height:20px
  }
  .adduserinquery div{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin-left: 1%;
    color: #142B59;
  }
 
  .adduserinquery span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin-left: 1%;
    color: #8E8E8E;
  }
  .chatstatusdiv{
    height: 18vh;
    margin-top: 1%;
  }
  .chatstatusdivspan{
    display: flex;
    /* justify-content: center; */
    margin-left: 7%;
    align-items: center;
  }
  .chatstatusdivspan img{
    height: 12px;
  }
  .chatstatusdivspan div{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 17px;
margin-left: 1%;
line-height: 27px;

color: #142B59;
  }
  .chatcloseadmin{
    display: flex;
    align-items: center;
    color: black;
    margin-left: 3rem;
    margin-top: 1%;
  }
  .Adminname{
    
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 24px;
margin-left: 1%;
color: #142B59;
  }
  .Adminname span{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 24px;

color: #8E8E8E;
  }
  .rolehighlight{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    margin-left: 1%;
    line-height: 24px;
    color: #1B4D9F;
    background: #DFDFDF;
    border-radius: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .dotdiv{
    height: 4px;
    width: 4px;
    background: #8E8E8E;
    border-radius: 50%;
    margin-left: 1%;
  }
  .chatstatusbyadmin{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    margin-left: 1%;
    line-height: 24px;
    color: #DE4545;

  }
  /* chat close fabricator */
.chatclosefab{
    display: flex;
    align-items: center;
    color: black;
    margin-left: 3rem;
    margin-top: 1%;
}
.fabname{
   
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    margin-left: 1%;
    color: #142B59;
      }
      .fabname span{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    
    color: #8E8E8E;
      }
.chatstatusbyfab{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    margin-left: 1%;
    line-height: 24px;
    color: #FFA800;
}