.selectedImage{
    height: 30vh;
    background: #E6E6E6;
    position: absolute;
    bottom: 23%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedImagediv img{
    height: 160px;
    border-radius: 12px;
}
.selectedImagediv{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.imagename{
    color: #142B59;
    font-size: 12px;
    margin-top: 2px;
}

.selectedpdfdiv img{
    height: 65px;
    border-radius: 12px;
}

.selectedpdfdiv{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.selectedPdf{
    height: 18vh;
    background: #E6E6E6;
    position: absolute;
    bottom: 23%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}