.notoficationmaindiv{
    /* position: relative; */
    width: 25vw;
    height: auto;
    position: absolute;
    /* overflow-y: scroll; */
    /* color: red; */
    top: 97%;
    right: 3%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: "20px";
    /* min-height: 25vh; */
    z-index: 10;
}
.showallnotoficationmaindiv{
    /* position: relative; */
    position: absolute;
    width: 25vw;
    /* height: auto; */
    height: 85vh;
    /* overflow-y: scroll; */
    /* color: red; */
    top: 97%;
    right: 3%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: "20px";
    /* min-height: 25vh; */
    z-index: 10;
}
.showallnotoficationmaindivmini{
    /* position: absolute; */
    /* width: 25vw; */
    height: 80vh;
    overflow-y: scroll;
    background: #FFFFFF;
    z-index: 10;
}
/* .showallnotoficationmaindiv::-webkit-scrollbar {display: none;} */
.showallnotoficationmaindivmini::-webkit-scrollbar {
    width: .4rem;
  }
  
  .showallnotoficationmaindivmini::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    /* margin-block: 14rem; */
  }
  
  .showallnotoficationmaindivmini::-webkit-scrollbar-thumb {

    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
.notificationspan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #142B59;
    padding-top: 4%;
    padding-bottom: 1%;
    padding-left: 7%;
}
.notificationdiv{
    color: #1B4D9F;
    padding-left: 7%;
    padding-right: 7%;
}
.notificationdiv div{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #292929;
}
.notifyhr{
    
    border: 1px solid gray;
    width: 100%;
margin: 0%;

}
.notificationdiv span{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 10px;
color: #868686;
}
.notificationdivblue span{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 10px;
color: #868686;
}
.notificationdivblue{
    background: rgba(27, 77, 159, 0.1);
    /* color: #1B4D9F; */
    padding-left: 7%;
    padding-right: 7%;
}
.notificationdivblue div{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
color: #292929;
}
.allnotification{
    padding-top: 0%;
    padding-left: 7%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    color: #142B59;
    margin-bottom: 4%;
    float: right;
    margin-right: 5%;
    cursor: pointer;
}