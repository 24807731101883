
.messagerecievediv {
    display: flex;
    /* align-items: center; */
    width: 50%;
    margin-top: 2%;
}

.namelogo {
    width: 31px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1%;
    margin-top: 5%;
    margin-right: 1%;
}
.usernametime {
    color: #142B59;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    /* margin-left: 1%; */
    margin-bottom: 0.5vh;

}
.usernametime span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #868686;
}