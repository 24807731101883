.useracccessmaindiv {
  width: 94%;

  color: black;
  margin-left: 5%;
  height: 100vh;
}
.useraccessheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 48px;
  margin-top: 3%;
  color: #142b59;
}

/* these are the final screen */
.useraccessscrolldiv {
  height: 69vh;
  overflow-y: scroll;
  margin-top: 1%;
}
.useraccessscrolldiv::-webkit-scrollbar {
  display: none;
}
.useraccessdata {
  margin-top: 1%;
  display: flex;
  padding: 1%;
  align-items: center;
  border-radius: 11px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}
.useraccesstablehaeding {
  margin-top: 3%;
  max-height: 75vh;
  display: flex;
  align-items: center;
}
.useraccessemaildiv {
  width: 25%;
  margin-left: 3%;
  overflow-x:scroll ;
white-space: nowrap;
}
.useraccessemaildiv::-webkit-scrollbar{display: none;}
.useraccessemaildiv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;

  color: #000000;
}

.useraccessnamediv {
  width: 20%;
  overflow-x:scroll ;
white-space: nowrap;
margin-left: 1%;
}
.useraccessnamediv::-webkit-scrollbar{display: none;}
.useraccessnamediv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;

  color: #000000;
}
.useraccesscompanynamediv {
  width: 20%;
  overflow-x:scroll ;
  white-space: nowrap;
  margin-left: 1%;
}
.useraccesscompanynamediv::-webkit-scrollbar{display: none;}

.useraccesscompanynamediv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;

  color: #000000;
}
.useraccessAccessdiv {
  width: 35%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;

  color: #000000;
}
.useraccessAccessdiv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;

  color: #000000;
  padding-left: 25%;
}
.useraccesstabledtata {
  display: flex;
  align-items: center;
  margin-top: 2%;
  display: flex;
  padding: 1%;
  align-items: center;
  border-radius: 11px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}
.accessallowd {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.accessallowdmaindivadmin {
  padding: 3px;
  background: #1b4d9f;
  border-radius: 12px;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessallowdmaindivadmin span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11.2292px;
  line-height: 14px;
  /* identical to box height */

  color: #ffffff;
}

.accessallowdmaindivdoc {
  padding: 3px;
  background: #3eb173;
  border-radius: 12px;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessallowdmaindivdoc span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11.2292px;
  line-height: 14px;
  /* identical to box height */

  color: #ffffff;
}
.accessallowdmaindivuser {
  padding: 3px;
  background: #92c5ea;
  border-radius: 12px;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessallowdmaindivuser span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11.2292px;
  line-height: 14px;
  color: #ffffff;
}
/* end here final screen */
