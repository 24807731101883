.Logindivforget {
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageforget {
    height: 8rem;
    margin-top: 39%;
}
.smalldivforget {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.passwordemailforget{
    height: 5vh;
    margin-top: 8%;
}
.otpmsgforget{
 
    color: #868686;
    font-size: .9rem;
}
.forgetpasswordinput{
    border: none;
    background-color: #F0F0F0;
    height: 6vh;
}
.forgetpasswordinput:focus{
    background: #F0F0F0;
}
.forgetpasswordspan{
    height: 6vh;
    width: 3vw;
    border: none;
    background-color: #F0F0F0 !important;

}
.forgetpasswordimage{
    height: 2vh;
}

.Lineargradientdivforget {
    height: 100vh;
    background-image: linear-gradient(#102756, #1B4D9F);
}

.h2designforget{
    color: #142B59;
    font-style: bold;
    /* margin-left: 23%; */
    margin-top: 9%;
    font-weight: 700;
}

.questionimageforget{
    position: absolute;
    left: 35%;
    top: 13%;
    height: 50vh;
}

.loginbtnforget {
    margin-top: 5%;
    width: 50%;
    height: 6vh !important;
    background-color: #1B4D9F;
    border: none;
    border-radius: 3rem;
}

.lineargradientminidivforget {
    position: relative;
    height: 100vh;
}

.buildingforget {
    width:100%;
    position: absolute;
    bottom: 0;
}
.emailerror{
    color: red;
    font-size: 14px;
    margin-top: 4%;
}


@media (max-width: 768px) {

    .Lineargradientdiv {
        /* width:30%; */
        height: 100vh;
        background-color: #22c049;
    }

}