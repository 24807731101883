.boldword {
    display: flex;
    align-items: center;
    color: gray;
    font-size: 19px;
    margin-left: 4%;
    font-weight: 700;
}

.boldblankdiv {
    background: gray;
    width: 2px;
    height: 20px;
    margin-left: 1%;
}

.blankuploaddataimage {
    height: 14px;
    margin-left: 1%;
}

.msgdiv {
    width: 80%;

}







.okk {
    height: 1px;
    /* background: red; */
    width: 100%;

}

.sendmessagediv {
    /* max-width: 80%; */
    width: auto;
    position: relative;
    /* margin-left: 68%; */
    display: inline-block;
    padding: 0.6rem 1rem;
    /* background: #9228C4; */
    border-radius: 12px 0px 12px 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    line-height: 22px;

    color: #FFFFFF;
}



.chatreplydiv {
    color: gray;
    margin-left: 2%;
}

/* .chatreplydiv{
    height: 12px;
    position: absolute;
    right: 3%;
    top: 0;
 
} */


/* .replymsgdiv{
    position: absolute;
  
    left: 2vw;
    background: #FFFFFF;
    top: 1vh;
    color: black;
    width: 5vw;
    display: flex;
    height: 4vh;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
} */





.selfreplydiv {
    color: gray;
    margin-right: 2%;
}

.inputbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60%;
}

.inputformsendmessage {
    height: 60%;
}

.inputboxmaindiv {
    background: #FFFFFF;
    border-radius: 12px;
    height: 11vh;
    position: absolute;
    /* top: 78%; */
    /* left: 40; */
    /* left: 40%; */
    width: 98%;
    /* width: 95; */
    bottom: 11%;
    left: 1%;
}



.closechatboxmaindiv {
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #D65C5C;
    height: 11vh;
    position: absolute;
    top: 78%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 2%; */
    width: 98%;
    left: 1%;
}

.closechatredspan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 146.02%;
    /* or 26px */

    text-align: center;
    margin-top: 2%;
    color: #D65C5C;
}

.closechatbluespan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 146.02%;
    /* or 26px */

    text-align: center;

    color: #194895;

}

/* .ispdftruemaindiv{
    background: #E3E3E3;
    border-radius: 12px;
    height: 15vh;
    padding-top: 7px;
    padding-left: 5px;
    padding-right: 8px;
} */


.selfispdftrue {
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 12px;
    /* max-width: 29%; */
    height: 13vh;
    color: red;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.uploadinchatclass {
    position: absolute;
    width: 4%;
    margin-left: 3%;
    height: 3vh;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}





.closereply {
    margin-right: 1%;
    color: gray;
    font-size: 20px;
}

.msgtobereplyed {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
}

.closechatexportbtn {
    width: 20%;
    height: 100%;
    background-color: #EDEDED;
    border-top-right-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom-right-radius: 12px;
}

.closechatexportbtn div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 155.02%;
    /* or 28px */

    text-decoration-line: underline;

    color: #194895;
}

.closechatexportbtn img {
    height: 18px;
}

.replyofamessagediv {}

.replytoamessagemaindiv {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    background: red;
}




/* reply */
.replydiv {
    width: 75%;
}





.selfreplydivofmessage {
    /* background: red; */
    padding: 1px;
    border-radius: 12px 0px 12px 12px;
    line-height: 24px;
}

.originalreplymessage {
    padding: 3%;
}

.selfreplymessageminidiv {
    height: 8vh;
    background: #F4F4F4;
    padding-left: 15px;
    border-radius: 12px 0px 12px 12px;
}

.replyingofmessage {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    color: #868686;
}

.chatareamaindivref {

    height: 60vh;
}

.chatArea {
    overflow-y: scroll;
    max-height: 60vh;
}

.chatArea::-webkit-scrollbar {
    display: none;
}



