.Allproject{
    width: 28%;
    margin-left: 4%;
    margin-top: 2.3%;
    margin-bottom: 5%;
    /* max-height: 87vh; */
    /* overflow-y:scroll; */
    /* opacity: 0 ;
    transition: opacity 400ms ease-in-out; */
    /* overflow-y: hidden; 
    overflow-x: hidden; */
 
}
.projectbtns{
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
}
.allproject{
    background: #1B4D9F;
    width: 24%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 2%;
}
.allprojectunselected{
    background: #F4F4F4;
    width: 26%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 2%;
}
.sharedwithme{
    background: #1B4D9F;
    width: 35%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 2%;
}
.sharedwithmeunselected{
    background: #F4F4F4;
    width: 35%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 2%;
}
.myproject{
    background: #1B4D9F;
    width: 26%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
}
.myprojectunselected{
    background: #F4F4F4;
    width: 26%;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13.1154px;
    line-height: 16px;
    color: #868686;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
}
.Allprojectlist{
    /* width:100%;  */
    max-height: 81vh;
     overflow-y:scroll;
     margin-top: 2%;

}
.Allprojectlist::-webkit-scrollbar {display:none;}
.Allproject span{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 24px;
color: #142B59;
}
