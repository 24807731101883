.wrapper{
    /* display: flex; */
    /* height: 20vh; */
    width: 22vw;

}
.accordian{
    width: 100%;
}
.item{
    background: #F4F4F4;
    margin-bottom: 1px;
    padding: 9px 10px;
    border-radius: 20px;
}
.itemblue{
    background: #194895;
    margin-bottom: 1px;
    padding: 9px 10px;
    border-radius: 20px;
}
.projectNamediv1{
    width: 90%;
    cursor: pointer;
    display: flex;
    align-items: center;
}
/* .projectNamediv1 img{
    width: 16px;
} */
.projecticonlogo{
    width: 16px; 
}

.whiteprojectnamespan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* padding-left: 7%; */
    overflow-x: scroll;
    color: #FFFFFF;
    white-space: nowrap;
    width: 80%;
    margin-left: 3%;
}
.whiteprojectnamespan::-webkit-scrollbar {display: none}
.blueprojectnamespan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #142B59;
    overflow-x: scroll;
    white-space: nowrap;
    width: 83%;
    margin-left: 3%;
}
.blueprojectnamespan::-webkit-scrollbar {display: none}
.projecthead{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 1%;
    width: 100%;
}
.title{
    color:#85662b;
    /* background-color: #194895; */
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 4%;
}
.title img{
    /* width: 15px; */
    height:100%;
}
.title  .imageContainer{
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title .sequenceName{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding-left: 4%;
    color: #FFFFFF;
}
.title .sequenceupdownclass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    padding-left: 2%;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
}
.content{
    color:#8b7f75;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5sec ucbic-bezier(0,1,0,1);
}
.content.show{
    /* height: auto; */
    width: 100%;
    /* margin-top: 3%; */
    /* overflow-y: scroll; */
    max-height: 100vh;
    transition: all 0.5sec ucbic-bezier(0,1,0,1);
}
/* .content.show::-webkit-scrollbar {display: none} */
.addnewsequencediv{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 1%;
    color: #FFFFFF;
  cursor: pointer;
}

.showuser2{
    width: 100%;
    margin-top: 3%;
    display: flex;
    align-items: center;
}
.showuser2 img{
    width: 1rem;
}
.reporticonside{
    cursor: pointer;
}

.totaluserinproject{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #868686;
    padding-left: 4%;
}
.totaluserinprojectwhite{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #DBDBDB;;
    padding-left: 4%;
}
.threedots{
    color: #142B59;
    cursor: pointer;
}
.threedotswhite{
    color: #FFFFFF;
    cursor: pointer;
}
.report{
    color: #FFFFFF;
    margin-top: 1%;
    margin-bottom:1%;
    /* padding-left: 10%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.report div{
    display: flex;
    align-items: center;
}
.report img{
    height: 16px;
    margin-left: 2%;
}
.report span{
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    margin-left: 30%;
}
.projectinnerdataul::-webkit-scrollbar {display:none;}
.projectinnerdataul{
    padding: 0% !important;
    /* list-style:none; */
    color: #FFFFFF;
   margin-bottom: 0% !important;
   margin: 0% !important;
   margin-top: 4%;
   /* max-height: 22vh; */
   /* overflow-y: scroll; */
} 

.projectinnerdatalist{

      color: white;
      white-space: nowrap;
      overflow-x: scroll;
      font-size: 14px;
      margin-bottom: none !important;
      max-width: 83%;
      width: 60%;
}
.projectinnerdatalist::-webkit-scrollbar {display: none}
.sequencedots{
    height: 5px;
    width: 6px;
    background: white;
    margin-right: 3%;
    border-radius: 50%;
}

.plusseqclass{
    width: 14px;
    margin-right: 2%;
}
.projectinnerlistdiv{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-left: 12%;
    margin-top: 1%;
}
.projectinnerlist{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.editsequencenameicon{
    height: 10px;
    margin-left: 2%;
    margin-top: 1%;
}
.projectbellicon{
    height: 2vh;
    color: #ffac33;
    margin-bottom: 1%;
    margin-left: 5%;
}
.sequencebell{
    color: rgb(255, 172, 51);
    height: 2vh;
    /* margin-left: 5%; */
    margin-bottom: 5%;
    width: 22px;
}
.editnotifydiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}

/* close project screen classes */

.closeprojectaccordian{
    width: 100%;
    position: relative;
}
.closeprojectcoverdiv{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width:100%;
    background: rgba(234, 27, 27, 0.1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.closeprojecthead{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 1%;
    width: 100%;
    opacity: 0.05;
}
.closeprojectshowuser{
    width: 100%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    opacity: 0.05;
}
.closeprojectshowuser img{
    width: 1rem;
}

.closeprojectshowsequence{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 24%;
    display: flex;
    width: 100%;
    background: rgba(234, 27, 27, 0.1);
    border-radius: 20px 20px 0px 0px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.closeprojectTitle{
    color:#85662b;
    /* background-color: #194895; */
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 4%;
    opacity: 0.05;
}
.closeprojectTitle img{
    /* width: 15px; */
    height:100%;
}
.closeprojectTitle  .imageContainer{
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.closeprojectTitle .sequenceName{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding-left: 4%;
    color: #142B59;
}
.closeprojectTitle .sequenceupdownclass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    padding-left: 2%;
    color: #FFFFFF;
}
.closeprojectreport{
    color: #142B59;
    margin-top: 1%;
    margin-bottom:1%;
    /* padding-left: 10%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}
.closeprojectreport div{
    display: flex;
    align-items: center;
}
.closeprojectreport img{
    height: 16px;
    margin-left: 2%;
}
.closeprojectreport span{
    color: #142B59;
    font-weight: 500;
    font-size: 14px;
    margin-left: 30%;
}
.closeprojectclosebtn{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 31px;

color: #E14747;
}
.closeprojectreopenproject{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
z-index: 200;
font-size: 12px;
line-height: 15px;
text-decoration-line: underline;

color: #0C48AA;
}
/* .closeprojectsequenceName{
    color: #142B59;
} */