.Logindivverify {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
}

.smalldivverify {
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.passwordemailverify {
    height: auto;
    width: 55%;
    margin-left: 18%;
    margin-top: 6%;
}

.imageverify {
    height: 20vh;
    margin-bottom: 3%;
}

.counter {
    margin-top: 2%;
    font-size: small;
    color: black;

}

.otpmsgverify {
    font-size: small;
    color: #868686;
}

.forgetpasswordinputverify {
    border: none;
    background-color: #F5F5F5;
}

.forgetpasswordinputverify:focus {
    background-color: #F0F0F0;
}

.forgetpasswordspanverify {
    height: 6vh;
    width: 3vw;
    border: none;
    background-color: #F5F5F5;

}

.forgetpasswordimageverify {
    height: 2vh;
}

.Lineargradientdivverify {
    height: 100vh;
    background-image: linear-gradient(#102756, #1B4D9F);
}

.h2designverifyverify {
    color: #142B59;
    font-weight: 700;
}

.questionimageverify {
    position: absolute;
    left: 35%;
    top: 13%;
    height: 50vh;
}

.loginbtnverify1 {

    width: 18%;
    height: 5vh;
    background-color: #1B4D9F;
    border: none;
    border-radius: 5rem;
    margin-top: 3%;
}

.loginbtnverify1 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
}

.lineargradientminidivverify {
    position: relative;
    height: 100vh;
}

.buildingverify {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.enterotpdivverify {
    height: 10vh;
    width: 20vw;
    display: flex;
    align-items: center;
    margin-top: 3%;
    justify-content: space-evenly;
}

.singleotpverify {
    height: 7vh;
    width: 50vw;
    background-color: #F0F0F0;
    border-radius: 27%;
    border-color: #868686;
}

.verifyotpverify {
    display: flex;
    border-color: #868686;
    margin-top: 2%;
    margin-left: 3%;
    border-color: #868686;
}

.verifyotpverify input {
    border: 1px solid #86868696;
}

.verifyotpverifyred {
    display: flex;
    margin-top: 2%;
    margin-left: 3%;
}

.verifyotpverifyred input {
    border: 1px solid red;
}

.resendverify1 {
    margin-top: 2%;
    font-size: .8rem;
    font-size: 550;
}

.resendotp button {
    border: none;
    background-color: none;
}

.verifyimage1 {
    position: absolute;
    left: 32%;
    top: 20%;
    height: 35vh;

}

.otperr {
    color: red;
    font-size: 10px;
    margin-top: 2%;
    font-weight: 700;
}

@media (max-width: 768px) {

    .Lineargradientdiv {
        height: 100vh;
        background-color: #22c049;
    }

}