.weblogindiv {
    width: 45%;
    height: 100vh;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px 2px rgba(95, 95, 95, 0.25);
    border-radius: 50px 0px 0px 50px;
 
    z-index: 10;
    transition: all 500ms;
    position: absolute;
    
}
.nameinputdiverror{
   
        height: 6.5vh;
        width: 68%;
        border-radius: 7px;
        display: flex;
        align-items: center;
        border: 1px solid red;
    
}
.buldingbackground{
 
    width: 100%;
    height: 28vh;
    display: flex;
   
    justify-content: center;
}
.webloginsmalldiv {

    display: flex;
    align-items: center;

    flex-direction: column;
    height: 100vh;
}

.animatedimagepasword {
    height: 3vh;

}

.webloginimage {
    height: 8rem;
    margin-top: 7%;

}

.animatedLineargradientdiv {
    height: 100vh;
    width: 100%;

}

.webloginspan{
    color: #142B59;
    font-style: bold;
 
    margin-top: 8%;
    font-weight: 700;
}

.animatedinputFile {
    background-color: #F0F0F0;
    height: 6vh;
    margin-left: 18%;
    width: 25vw;
    margin-top: 3%;
    border-radius: 10px;
    border: none
}

.webforgetpassword {
    margin-top: 4%;

    color: #1B4D9F;
}

.animatedforgetpassword span {

    font-style: normal;
    font-weight: 450;
    font-size: 1rem;
    line-height: 28px;
    color: #1B4D9F;
    cursor: pointer;
}

.webloginbtn {
    margin-top: 6%;
    width: 23%;
    height: 6vh !important;
    background-color: #1B4D9F;
    border: none;
    border-radius: 5rem;
}

.webloginbtn span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
}

.animatedlineargradientminidiv {
    position: relative;
    height: 100vh;
}

.animatedbuilding {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
}

.animatedhellospan {
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    top: 15%;
    left: 34%;
    font-size: xxx-large;
    font-weight: 500;
    font-size: 2rem;
}

.animateddetailspan {
    color: #FFFFFF;
    position: absolute;
    left: 31.54%;
    top: 27.23%;
    width: auto;
    bottom: 57.13%;
    font-weight: 400;
    font-size: 1rem;
 
    text-align: center;
}

.nameinputdiv {
    height: 7vh;
    width: 52%;
    display: flex;
    align-items: center;
    margin-left: 7%;
    margin-top: 2%;
    justify-items: center;
}

.websignupemailspan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    display: flex;
    border-radius: 0%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #F2F6FF;

}

.animatednameimage {
    height: 2vh;
}



.webloginform {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
}

.animatedjourneyspan {
    color: #FFFFFF;
    position: absolute;
    left: 39.54%;
    top: 31.23%;
    bottom: 57.13%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 151.52%;
    text-align: center;
}

.animatedsignupbtn {
    position: absolute;
    top: 45%;
    left: 37%;
    background: transparent;
    border: 1px solid white;
    width: 26%;
    border-radius: 28px;
    border-color: #FFFFFF;
    height: 6vh;

}

@media (max-width: 768px) {

    .Lineargradientdiv {
    
        height: 100vh;
        background-color: #22c049;
    }

}


.animatedleftdivmain {
    background-image: linear-gradient(#102756, #1B4D9F);
    height: 100vh;
    width: 100%
}

.animatedrightdivmain {
    background-color: #ffffff;
    height: 100vh;
    width: 100%
}

.webcreateAccount {
    position: relative;
    width: 100%;
    height: 16vh;
}

.animatedcreateAccountspan {
    position: absolute;
    top: 41%;
    left: 29%;
    font-size: xx-large;
    font-weight: 700;
    color: #142B59;
    font-family: 'Inter';
    font-style: normal;
}

.webformdiv {
    height: 80vh;
    width: 99%;
    overflow-y: scroll;
}

.webformdiv::-webkit-scrollbar {
    width: .4rem;
  }
  
  .closesignupbtn{
    position: absolute;

    left: 5%;
    top: 1%;
    height: 7%;
    z-index: 20;
  }
  .webformdiv::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
 
  }
  
  .webformdiv::-webkit-scrollbar-thumb {

    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }


.animatedmainnumberselectclass {
    display: flex;
    width: 100%;

    margin-bottom: 3%;
}

.webselectdropdown {
    display: block;
    border: none;
    background-color: #F2F6FF;
    height: 6vh;
 
    cursor: pointer;
    width: 4vw;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.webselectdropdownerror {
    display: block;
    border: none;
    background-color: #F2F6FF;
    height: 6.5vh;
 
    cursor: pointer;
    width: 4vw;
    display: flex;
    border: 1px solid red;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}
.webselectdropdown span {
    font-size: 11px;
    font-weight: 600;
}

.animatedselectedinput {
    height: 6vh;
    width: 15.4vw;
    border: none;
    background-color: #F0F0F0;
    margin-left: -4%;

}
.animatednameinput1 {
    height: 6vh;

    border: none;
    background-color: #F2F6FF;
    color: #868686;


}

.professionselect{
    height: 6vh;
    border: none;
    background-color: #F2F6FF;
    width: 88%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 450;
    justify-content: space-between;
    font-size: 14px;
    line-height: 29px;
    
    color: black;
}
.professionselect span{
      /* font-family: 'Aspira'; */
      font-style: normal;
      /* font-weight: 550; */
      font-size: 14px;
      /* line-height: 29px; */
      /* color: #a19f9f; */
      /* height: 6vh; */
      border: none;
      background-color: #F2F6FF;
      color: #868686;

}

.animatednameinput1:focus {
    background: #F2F6FF;
}

.animatednameinput1::placeholder {

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 29px;

    color: #868686;
}

.animatedselectedinputcountry {
    height: 6vh;
    width: 12.4vw;
    border: none;
    background-color: #F0F0F0;
    margin-left: -4%;
}

.animatedselectedinput:focus {
    background-color: #F0F0F0;
}

.animatedselectdropdown:focus-visible {
    border: none !important;
    outline: none;
}

.webselectedinputdiv {
    border: none;
    outline: none;
    width: 83%;
    margin-left: 1%;
    height: 5vh;
    display: flex;
}
.webselectedinputdiverror {
    border: 1px solid red;
    outline: none;
    width: 53%;
    margin-left: 1%;
    height: 6.5vh;
    display: flex;
    border-radius: 5px;
}

.webselectedinputdiverror input{
    border: none;
    outline: none;
    background-color: #F2F6FF;
    width: 85%;
    height: 6vh;
    border-radius: 0%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.webselectedinputdiverror input:focus{
    background-color: #F2F6FF;
}
.webselectedinputdiv input {
    border: none;
    outline: none;
    background-color: #F2F6FF;
    width: 55%;
    height: 6vh;
    border-radius: 0%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.webselectedinputdiv input:focus {

    background-color: #F2F6FF;

}


.websignupform {
    position: relative;
    margin-left: 30%;
    height: 80vh;

}

.animatednamediv1 {
    height: 8vh;
    width: 67%;
    display: flex;
    align-items: center;
}

.nameinputdiv1{
    height: 6vh;
    width: 68%;
    border-radius: 7px;
    display: flex;
    align-items: center;
}

.animatedname {
    height: 8vh;
    width: 71%;
    margin-top: 2%;
 
}

.animatedtouchname {

    height: 8vh;
    width: 54%;
    margin-top: 2%;
    outline-color: rgb(9, 9, 80);

}

.animatednamespan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    background-color: #F0F0F0;
}

.animatedemailimage {
    height: 11px
}

.animatednameimage {
    height: 14px;
}

.webemailspan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    display: flex;
    border-radius: 0%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #F2F6FF;

}
.webloginemailspan {
    height: 7vh;
    width: 2.2rem;
    border: none;
    display: flex;
    border-radius: 0%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #F2F6FF;

}
.webnameinput {
    height: 7vh;
    width: 100%;
    border: none;
    background: #F2F6FF;
    border-radius: 0%;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;

}

.webnameinput:focus {
    background: #F2F6FF;
}



.animatedselectcountry {
    display: flex;
    margin-bottom: 3vh;
}

.animatedselectcountryspan {
    width: 21%;
    BACKGROUND-COLOR: #F0F0F0;
    height: 5vh;
}

.animatedselectcountryspan span {
    padding: 4%;
}

.animatedselectCountryvalue {
    width: 34%;
    height: 3vh;
    border: none;
    outline: none;
}

.animatedphoneInput {
    height: 8vh;
    width: 73%;
    border: none
}

.animatednamedivbtn {
    height: 8vh;
    width: 54%;
    margin-top: 4%;
}

.animatedsignbtn {
    margin-top: -1%;
    width: 55%;
    height: 6vh;
    margin-left: 30%;
    background-color: #1B4D9F;
    border: none;
    border-radius: 20rem;
}

.css-13cymwt-control {
    border: none;
    outline: none !important;
}

.animatedLineargradientdiv {
    height: 100vh;

}

.animatedlineargradientminidiv {
    position: relative;
    height: 100vh;
}

.animatedbuilding {
    height: 28vh;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.animatedfinalLogo {
    height: 16vh;

    position: absolute;
    left: 40%;
    top: 6%;
}

.nameimage {
    height: 2vh;
}

.animatedhellospan1 {
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;

    margin-top: 5%;
    margin-bottom: 2%;
    font-size: xxx-large;
    font-weight: 500;
    font-size: 2rem;

}

.animateddetailspan1 {
    color: #FFFFFF;
  
    width: 50%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2;
    margin-bottom: 2%;
    text-align: center;
}

.animatedspandiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 70%;
}

.animatedjourneyspan1 {
    color: #FFFFFF;
    font-weight: 400;
    text-align: center;
}

.animatedloginbtn1 {
    width: 28%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 28px;
    border-color: #FFFFFF;
    height: 6vh;
    margin-top: 3%;
}

.animatedloginbtn1 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}
.Errors {
    color: red;
    font-size:12px;
}
.shownumbermaindiv {
    position: absolute;
    top: 29%;
    left: 0%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.22);
    border-radius: 30px;
    width: 45%;
    padding: 10px;
    height: 28vh;
    flex-direction: column;
    overflow-y: scroll;

}
.countrycode{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #1B4D9F;
    margin-left: 8%;
 
    width: 21%;
}
.numbercountryname{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-left: 3%;
    color: #1B4D9F;
}
.shownumbermaindiv::-webkit-scrollbar {
    width: .4rem;
  }
  
  .shownumbermaindiv::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    margin-block: 4rem;
  }
  .shownumbermaindiv::-webkit-scrollbar-thumb {
    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
.checkboxnumber{
    height: 2.5vh;
    width: 20px;
    border: 2px solid #C6C6C6;
border-radius: 5px;
}

.numberdiv {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.Scroll_DIv{
    overflow: hidden;
}
.showcountrymaindiv {
    max-height: 22vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.22);
    border-radius: 30px;
    position: absolute;
    top: 70%;
    left: 0%;
    padding: 1%;
    width: 53%;
    overflow-y: scroll;
}
.showcompanyname{
    max-height: 25vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.22);
    border-radius: 12px;
    position: absolute;
    top: 60%;
    left: 0%;
    /* padding: 1%; */
    width: 70%;
    overflow-y: scroll; 
}
.showcompanyname::-webkit-scrollbar {
    width: .4rem;
  }
  
  .showcompanyname::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    margin-block: 3rem;
  }
  
  .showcompanyname::-webkit-scrollbar-thumb {

    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
  .companynamediv{
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 2%;
    font-size: 14px;
    font-weight: 600;
    border-radius: 14px;
  }
  .companynamediv:hover {
    background: #1B4D9F;
    color: white;
}
.showprofessionmaindiv {
    max-height: 25vh;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.22);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 0%;
    padding: 1%;
    width: 67%;
    overflow-y: scroll;
}
.showprofessionmaindiv::-webkit-scrollbar {display: none;}

.showcountrymaindiv::-webkit-scrollbar {display: none;}
.countrydiv {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 14px;
}
.professiondiv {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 14px;
}
.professiondiv:hover {
    background: #1B4D9F;
    color: white;
}

.countrydiv:hover {
    background: #1B4D9F;
    color: white;
}