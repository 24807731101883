.Querypopover{
    height: 10vh;
}

/* to set the width of query popover and report popover we have to the set the width of this class golobally  */

/* .css-1uhqtou{
    width: 65%;
} */

/* to set the width of query popover and report popover we have to the set the width of this class golobally end here  */



.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
background-color: transparent !important;
    box-shadow: none !important;
    margin-left: 1rem !important;
    height: 30vh;
    width: 15%;
    border-radius: 10px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3bmhjh-MuiPaper-root-MuiPopover-paper{
    overflow: hidden;
    height: auto;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper .querylistofdata{

    color: white !important;
    list-style: none;
   
   overflow-x: none !important;

}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper .querylistofdata ul{

    padding-left: 0;
    overflow-y: scroll;
    height: auto;
    margin-bottom: 0;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper .querylistofdata ul::-webkit-scrollbar {display:none;}
.queryheading{
    display: flex;
    align-items: center;
}
.queryinnerlist{
    display: flex;
    margin-top: 3%;
    justify-content: space-around;
    align-items:center;
}
.queryinnerdeletelist{
    display: flex;
    margin-top: 3%;
    /* justify-content: space-around; */
    align-items:center;
}
.queryinnerdeletelist:hover{
    background-color: #ffffff;
    color: #142B59;
    border-radius: 5px;
    }
    .queryinnerdeletelist:hover .querydataname{
        color: #142B59 !important;
    }
    .queryinnerdeletelist:hover .querychatreddotsdeleted{
        color: #142B59 !important;
    }
    .querychatreddotsdeleted{
        height: 8px;
        width: 8px;
        background: red;
        border-radius: 50%;
    margin-right: 2%;
   
    }
.queryinnerlist:hover{
background-color: #ffffff;
color: #142B59;
border-radius: 5px;
}
.queryinnerlist:hover .querydataname{
    color: #142B59 !important;
}
.queryinnerlist:hover .querythreedots{
    color: #142B59 !important;
}
.queryheading span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: #FFFFFF;
    padding-left: 6%;
}
.querychatreddots{
    height: 8px;
    width: 8px;
    background: red;
    border-radius: 50%;

    position: relative;
    left: 0;
}
.querybell{
    height: 2vh;
    color: #ffac33;
}
/* remove the box shandow of query  */
.css-1dmzujt{
    box-shadow: none !important;
    background-color: transparent !important;
    margin-left: 2%;
    width: 30%
}
/*  end here  remove the box shandow of query */
.querydataname{
   
    font-size: 14px;
     padding-left: 2%;
    font-size: 14px;
    width: 70%;
    color: white;
    white-space: nowrap;
    overflow: scroll;
}
.querylistofdata ul{
    padding-left: 0.5rem !important;
}
.addqueryimage{
    height: 18px;
    margin-right: 2%;
}
 .querydataname::-webkit-scrollbar {display: none;}
.querythreedots{
    color: white;
    width: 10%;
}

.css-xn7u46-MuiTypography-root{
    padding-right: 20px !important;
}
.addquerybtn{
    font-size: 1rem;
    margin-top: 3%;
color: white;
}