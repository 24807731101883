.deletequerymodal{
    height: 29vh;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 1.46936px 1.46936px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 70%;
    /* padding: 10px; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.deletequerytrash{
    color: red;
    height: 6vh;
    width: 10%;
    margin-top: 3%;
   
}
.deletequeryspan1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #383838;
}
.deletequeryspan2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #868686;
}
.querydeletebtns{
    display: flex;
    justify-content: space-between;
    width: 56%;
    margin-bottom: 0%;
    margin-top: 4%;
}

.querydeletebtndelete{
    width: 40%;
    background: #1B4D9F;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.querydeletebtncancle{
    width: 40%;
    background: #1B4D9F;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}