.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .dropdown-container {
    width: 100%;
    margin-top: 2%;
    /* background: red; */
  }