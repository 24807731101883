/* screen before select any chat file  */

.chatsidescreen {
    width: 65%;
    align-items: center;
    background-color: #F5F5F5;
    border-radius: 1rem;
    margin-top: 5%;
    height: 100vh;
}

.chatsidescreendiv {
    /* width: 95%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 95%;
    margin-top: 23%;
    margin-left: 15%;

}

.chatsidescreendiv img {
    width: 72%;
}

.queryspan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #7E7E7E;
    width: 48%;
    margin-left: 27%;
    margin-top: 4%;
}

/* end here  */


/* main chat file screen is here  */
.mainchat {
    width: 65%;
    height: 98vh;
    border-radius: 1rem;
    margin-top: 2%;
    background: #F4F4F4;
    position: relative;

}

.chatheader {
    height: 9vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: #194895;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.chatheader img{
    height: 20px;
    padding-left: 2%;
}

.chatheader span {
    padding-left: 2%;
    width: 85%;
}

.chatheaderimagespandiv {
    width: 15%;
}
.chatheaderimagespandiv span{
    margin-left: 4px;
}

.chatheaderimagespandiv img {
    height: 20px;
    /* width: 21px; */
    padding-bottom: 4%;
}

.chatdocs {
    background: #E3E3E3;
    height: 7vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chatdocs img {
    height: 3vh;
    padding-left: 2%;
}

.chatdocs span {

    color: #868686;
    padding-left: 3%;

}

.anchor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #142B59;
    padding-left: 2%;
    z-index: 2000;
}

.day {
    position: relative;
    margin-top: 3%;

}

.today {
    text-align: center;
    width: 10%;
    margin-left: 48%;
    margin-top: -4%;
    background: #E3E3E3;
    border-radius: 50px;
    padding: 5px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 19px;
    color: #142B59;
}

.HrLine {
    /* position: absolute; */
    margin-top: 4%;
    width: 100%;

}




/* .recievedmsgdiv:hover .chatreplydiv{
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
 
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
border-radius: 12px;
} */






/* .sendmessagediv:hover .selfreplydiv{
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
border-radius: 12px;
} */
.changedocumentclass{
    position: absolute;
    width: 5%;
    opacity: 0;
}

.userinputclass{
    padding-left: 4%;
    border: none;
    outline: none;
    width: 81%;
    border: none;
    height: 100%;
    background: none;
} 
.userinputclass::placeholder{
/* padding-left: 5%; */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #868686;
}
.sendmsgbtn{
    background: rgb(27, 77, 159);
    border-radius: 10px;
    margin-right: 3%;
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: center;
    width: 2.5vw;
}

.sendmsgbtn img{
    height: 24px;
    padding: 7%;
}
.replypopover{
    background: #FFFFFF;
box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
border-radius: 12px;
display: flex;
align-items: center;
justify-content: center;
width: 20%;
}