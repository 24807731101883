.SubQueryOptions {
    width: auto;
    background: #F4F4F4;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    background-color: transparent !important;
    /* box-shadow: none !important; */
    margin-left: 1rem !important;
    height: 30vh;
    width: 15%;
    border-radius: 10px;
}

/* .css-ahj2mt-MuiTypography-root { */
    /* margin-left: 1rem !important; */
/* } */
/* .css-9l3uo3{
    width: 49% !important;
} */

.editquery{
    height: 5vh;
    margin-top: 1%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;

    color: #142B59;
}
.editquery:hover{
    background: #194895;
    color: #FFFFFF;
}
.movequery:hover{
    background: #194895;
    color: #FFFFFF;
}
.deletequery:hover{
    background: #194895;
    color: #FFFFFF;
}
.movequery{
    height: 5vh;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #142B59;
    display: flex;
    align-items: center;
}
.movequery img{
  height: 2vh;
  padding-left: 3%;
  margin-right: 4%;
  
}
/* .movequery span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding-left: 5%;
    color: #142B59;
} */

.deletequery{
    height: 5vh;
    margin-bottom: 1%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #142B59;
    display: flex;
    align-items: center;
}

.editquerynamelogo{
  color: #142B59;
        padding-left: 4%;
        height:2vh;
   
        width: 10%;
        margin-right: 4%;
}
.horizontalline{

    height: 2px;
    border: 1px solid #80808066;
    width:100%;
    margin-top:0%;
    margin-bottom:0%;
}
.deletequerylogo{
    width: 18px;

        color:red;    
         padding-left: 4%;
        height: 4vh;
        margin-right: 4%;
   
}