.webheader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 15vh;
    position: fixed;
    top: 0%;
}
.webheaderlogo img{
    height: 18vh;
}
.webheaderlogo{
    margin-left: 2%;
}
.webheaderloginsignupbtn{
    width: 14%;
    display: flex;
    align-items: center;
  
    margin-right: 3%;
}
.webheaderlogin{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 15px;
cursor: pointer;
line-height: 22px;
width: 30%;
color: #292929;
}
.webheadersignup{
    background: #1B4D9F;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    margin-left: 7%;
    color: white;
    font-size: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}