.addnewsequenceinput{

}
.newsequencebody{
    height:25vh;
    background: #FFFFFF;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    
}
.newsequencediv{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
}
.sequencenameinput{
    height: 7vh;
    width: 75%;
    border-radius: 10px;
    padding: 5%;
}

.addnewsequenceinput{
    height: 10vh;
    border-radius: 10px;
    width: 75%;
}
.uploadtext{
    margin-top: 2%;
    margin-bottom: 1%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 71%;
    line-height: 24px;
    color: #1B4D9F;
}
.uploaddocument{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    width: 35%;
    text-decoration-line: underline;
    
    color: #1B4D9F;
}
.newsequencebtn{
    width: 25%;
    background: #1B4D9F;
    border-radius: 30px;
    margin-top: 4%;
    height: 5vh;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
}