.newpasswordLogindiv {
 
    height: 100vh;

    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.newpasswordimage{
    margin-bottom: 4%;
    height: 20vh;
}
.paragraph{
    color: red;
    font-size: 2vh;
}
.newpasswordparagraph{
 
    font-size: x-small;
    color: red;
}
.chnagepassworddiv{
    width: 47%;
}
.newpasswordh2designverify{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 44px;
    color: #142B59;
}
.newpasswordsmalldiv {
    /* margin-left: 12%; */
    /* margin-top: 0%; */
}
.newpasswordpasswordemail{
    height: auto;
    width: 55%;
    /* margin-left: 18%;
    margin-top: 6%; */
}
.newpasswordotpmsg{
    /* margin-top: 2.3vh;
    margin-left: 30%; */
    font-size: small;
    color: #868686;
}
.newpasswordforgetpasswordinput{
    border: none;
    background-color: #F5F5F5;
}
.newpasswordforgetpasswordinput:focus{
    background-color: #F0F0F0;
}
.newpasswordpassworddiv{
    width: 100%;
    height: 5vh;
    margin-top:5%
}
.newpasswordpassworddiv2{
    width: 100%;
    height: 5vh;
    margin-top:5%
}
.newpasswordemailspan{
    border: none;
    background:#F0F0F0;;

}
.newpasswordnameinput{
    background: #F0F0F0;
    border: none;
}
.newpasswordnameinput:focus{
    background: #F0F0F0;
}
.forgetpasswordspan{
    height: 6vh;
    width: 3vw;
    border: none;
    background-color: #F5F5F5;

}
.forgetpasswordimage{
    height: 2vh;
}

.Lineargradientdiv {
    height: 100vh;
    background-image: linear-gradient(#102756, #1B4D9F);
}

.h2designverify{
    color: #142B59;
    font-style: bold;
    /* margin-left: 27%;
    margin-top: 3%; */
    font-weight: 700;
}

.questionimage{
    position: absolute;
    left: 35%;
    top: 13%;
    height: 50vh;
}

.newpasswordloginbtn {
    height: 5.4vh;
    background-color: #1B4D9F;
    border: none;
    border-radius: 5rem;
    margin-left: 34%;
    width: 30%;
    margin-top: 5%;
}

.lineargradientminidiv {
    position: relative;
    height: 100vh;
}

.building {
    width:100%;
    position: absolute;
    bottom: 0;
}
.enterotpdiv{
height: 10vh;
width: 20vw;
display: flex;
align-items: center;
justify-content: space-evenly;
}

.passworddiv{
    height: 8vh;
    width: 40%;
    /* margin-left: 21%;
    margin-top: 5%; */
}
.passworddiv2{
    height: 8vh;
    width: 40%;
    /* margin-left: 21%;
    margin-top: 2%; */
}
.verifyimage{
    position: absolute;
    left:29%;
    top: 13%;
    height: 40vh;
}

@media (max-width: 768px) {

    .Lineargradientdiv {
        /* width:30%; */
        height: 100vh;
        background-color: #22c049;
    }

}