.createprojectmaindiv {
  margin-left: 10%;
  margin-top: 4%;
  width: 75%;
  margin-right: 3%;
}
.inputdiv {
  height: 8vh;
  width: 100%;
}
.addprojectspan {
  height: 8vh;
  width: 2.2rem;
  border: none;
  background-color: #f0f0f0;
  /* border-radius: none !important; */
}
.createprojectimg {
  height: 3vh;
}
.createprojectinput {
  height: 8vh;
  width: 15vw;
  border: none;
  background-color: #f0f0f0;
}
.createproject {
  background: #1b4d9f;
  border-radius: 30px;
  padding: 10px;
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Aspira'; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  cursor: pointer;
  color: #ffffff;
}
.createprojectimagediv img {
  height: 39vh;
  margin-left: 28%;
}
.createprojectinput:focus {
  background: #f0f0f0;
}
