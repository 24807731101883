.SlideScreen1div{
    margin-top: 3%;
    height: 100vh;
    width: 90%;
    background-color: 
    #F5F5F5;
    margin-left: 6%;
}
.sliderhi{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    /* line-height: 142%; */
    text-align: center;
    color: #1B4D9F;
    margin-top: 3%;
    margin-bottom: 2%;
}
.sliderp{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    /* line-height: 142%; */
    text-align: center;
    color: #1B4D9F;
}

.sliderbtn1{
    background: #1B4D9F;
border-radius: 30px;
margin-top: 2%;
margin-left: 43%;
border: none;
width: 11%;
}
.sliderbtn1 span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2rem;
    color: #ffffff;
}

.slider1img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider1img img{
    height: 40vh;
}
.spares{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}
.spares .spare{
    background-color: white;
    border: 2px solid #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
.spares .active{
    background-color: #1B4D9F;
    width: 11px;
    height: 11px;
    margin-right: 1%;
    border-radius: 50%;
}
