/* home header desiging */
.isEmailverifyhomeheader{
    width: 100%;
   margin-top: 4%;
    padding-left: 2%;
    padding-right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homeheader {
    width: 100%;
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.homeheaderspan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    /* width: 20%; */
    width: 22%;
    color: #142B59;
    /* margin-right: 8%; */
    margin-right: 5%;
}

.homeheadersearch {
    background-color: #E3E3E3;
    border-radius: 1.5rem;
    width: 31%;
    border: none;
    height: 42px;
    display: flex;
    align-items: center;
    margin-right: 14%;
}

.homeheadersearchinput {
    background: transparent;
    border: none;
    outline: none;
    width: 80%;
    height: 100%;
    padding: 4%;
}

.homeheadersearchinput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #142B59;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    /* padding-left: 2%; */
}

.homeheadersearchicon {
    margin-left: 3%;
    color: #142B59;
}

.homeheaderarrow {
    color: #142B59;
}

.homeheaderadduser {
    background: #1B4D9F;
    /* width: 15%; */
    border-radius: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 7vh; */
    /* margin-top: 1%; */
    margin-right: 2%;
    cursor: pointer;
    padding: .5rem 1.2rem !important;
}

.homeheaderadduser span {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
}

.homeheadernotification {
    width: 4%;
    cursor: pointer;
    /* margin-top: 1%; */
}

.homeheadernotification img {
    height: 4vh;
}

.homeheaderprofileimage {
    width: 6%;
    cursor: pointer;
    /* height: 100%; */
}


.homeheaderprofileimage svg {
    height: 8vh;

}

.homeheaderprofileimage img {
    height: 6vh;
}

.edit {
    width: 3%;
}

.dropdownbtn {
    background: none;
    height: 5vh;
    padding-top: 2%;

}

.dropdownbtn img {
    height: 2vh;

}

.sidebaroptionspandiv {
    color: #142B59;
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 0.5rem;
}

.sidebaroptionspandiv1 {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 0.5rem;
}


.adduserbtn {
    height: 5vh;
    position: absolute;
    top: 90%;
    left: 21%;
    color: white;
    border: 1px solid white;
    width: 50%;
    border-radius: 1rem;
    background-color: #FFFFFF;
}

.adduserbtn span {

    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 26px;
    color: #194895;
    margin-left: 9%;
    margin-top: 2%;
}

.profilepopup {
    position: relative;
    width: 14vw;
    height: auto;
    position: absolute;
    /* color: red; */
    top: 97%;
    right: 3%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: "20px";
    /* min-height: 25vh; */
    z-index: 10;
}

.popupimagenmaehaeding {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    cursor: pointer;
    padding: 5%;
    /* padding-left: 17%; */
    margin-top: 8%;


}

.profilepopupheadingemail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .7rem;
    line-height: 20px;
    color: #868686;


}

.profilepopupheadingname {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    line-height: 20px;
    padding-left: 5%;
    white-space: nowrap;
    overflow-x: scroll;
    color: #1B4D9F;
}
.profilepopupheadingname::-webkit-scrollbar {display: none;}
.profilepopupheading {
    width:83%
}
.editProfile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 2%;
    cursor: pointer;
}



.setting {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 6%;
    cursor: pointer;
}
.settingsubmenu{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    cursor: pointer;
    color: #1B4D9F;

    padding-top: 6%;
}
.changepassword{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 6%;
    cursor: pointer;
}
.deleteuser{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 6%;
}

.logout {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 6%;
    cursor: pointer;
    padding-bottom: 6%;
}
.contactusdiv{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    padding-left: 7%;
    line-height: 20px;
    color: #1B4D9F;
    padding-top: 6%;
    cursor: pointer;
}