.chatsidecreateprojectdiv{
    width: 65%;
    background: #F4F4F4;
    height: 91vh;
    margin-top: 2%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.createprojectuparrow{
    position: absolute;
    left: 78%;
    top: 5%;
}
.createprojectuparrow img{
    height: 12vh;
}

.createprojectdownarrow{
    position: absolute;
    top: 65%;
    left: 34%;
}
.createprojectdownarrow img{
    height: 12vh;
}

.chatsidecreateprojectimg{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
    margin-right: 4%;
}
.chatsideparagraphmain{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: center;
    color: #1B4D9F;
}

.chatsideparagraph{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 2%;
    color: #1B4D9F;
}
.chatsidecreateprojectimg img{
    width: 60%;
}

.chatsidecreateprojectbtn{
    background: #1B4D9F;
    border-radius: 30px;
    border: none;
    width: 25%;
margin-top: 2%;
    height: 5vh;
}
.chatsidecreateprojectbtn span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2rem;
    color: #ffffff;
}