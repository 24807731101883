.editprofilemaindiv{
    width:97%
}
.editprofilespan{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 48px;
    color: #142B59;
    margin-bottom: 2%;
    margin-left: 4%;
    margin-top: 2%;
}
.editprofilespan img{
    height: 1.2rem;
    margin-left: 1%;
}
.editprofilespan{
    color:"black"
}
.editprofilediv1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
}
.editprofileusername{
    display: flex;
    border-radius: 11px;
    margin-left: 4%;
    width: 40%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    background: #F0F0F0;

}
.profilenamespan{
    height: 3vh;
    margin-left: 2%;
    margin-top: 2.3%;
    width: 5%;
    color: gray;
    /* background-color: #F0F0F0; */
}
.profilenamespan img{
height: 16px;
}
.profilenameinput{
    height: 6vh;
    width: 88%;
    border: none;
    border-radius: 0% !important;
    display: flex;
    align-items: center;
   background: #F0F0F0;
    margin-left: 2%;
color: #868686;
}

.profilenameinput:focus{
    background: #F0F0F0;
}
.editprofileuseremail{
    display: flex;
    background-color: none !important;
    background: #F0F0F0;
    width: 40%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
}
.profileuseremail{
    /* background-color: #F0F0F0 */
    background-color: none !important;
    border: none;
    border-radius: 0%;
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.profilediv2{
    display: flex;
    width: 100%;
margin-top: 2%;
    justify-content: space-between;
}
.profileselect{
    margin-left: 9%;
    width: 10%;
    padding: 1%;
    height: 6vh;
    border: 1px solid #D8D8D8;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
background: #F0F0F0;
}
.profileselect span{
    font-size: 15px;
}
.selectednumber{
    display: flex;
    width: 44%;
    align-items: center;
    
   
}
.profileselectdropdown{
    display: block;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    height: 6vh;
    
   
}

.profileselectedinputdiv{
    outline: none;
    width: 100%;
    margin-left: 1%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    height: 6vh;
    display: flex;
    background: #F0F0F0;
}
.profileselectedinput{
    width: 89%;
    background: #F0F0F0;
    border: none;
}
.profileselectedinput:focus{
    background: #F0F0F0;
}
.profilecompanyname{
    display: flex;
    background-color: none !important;
    width: 40%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    background: #F0F0F0;
}
.profilediv3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
}
.profileprofession{
    display: flex;
    border-radius: 11px;
    margin-left: 4%;
    width: 40%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    background: #F0F0F0;
}
.profilecity{
    display: flex;
    background-color: none !important;
    width: 40%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    background: #F0F0F0;
}
.profilediv4{
    margin-top: 2%;
}
.profilecountryselect{
    width: 10%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    height: 6vh;
    margin-left: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F0F0;
}
.profilecountryselectinput{
    outline: none;
    width: 100%;
    margin-left: 1%;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    height: 6vh;
    display: flex;
    background: #F0F0F0;
}
.profilecompanyimagae{
    height: 2vh;
}
.profileprofessionimage{
    height: 2vh;
}
.profilecityimage{
    height: 2vh;
}
.profilediv5{
    display: flex;
    /* margin-right: 10%; */
    width: 100;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4%;
}
.profilecancle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;

    color: #4C4C4C;
    margin-right: 3%;
  

}
.profilesave{
background: #1B4D9F;
border-radius: 30px;
color: white;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
}
.profilecountryimg{
    height: 2vh;
}

/* show number as for country code  */

.shownumberdivinprofile{
    position: absolute;
    top: 49%;
    background: #F0F0F0;
    left: 3%;
    width: 40%;
    flex-direction: column;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}

.shownumberminidivinprofile {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    color: black;
}
.shownumberminidivinprofile:hover {
    background: #d8d8d8;
}


.showcountrydivinprofile{
    height: 25vh;
    position: absolute;
    top: 79%;
    background: #F0F0F0;
    left: 3%;
    width: 40%;
    overflow-y: scroll;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}
.showcountryminidivinprofile {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    color: black;
}
.showcountryminidivinprofile:hover {
    background: #d8d8d8;
}


.showcompanydivinprofile{
    position: absolute;
    top: 49%;
    background: #F0F0F0;
    left: 58%;
    width: 39%;
    overflow-y: scroll;
    max-height: 33vh;
    /* height: auto; */
    /* min-height: 19vh; */
    flex-direction: column;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}
.showcompanydivinprofile::-webkit-scrollbar {display: none;}



.showprofessiondivinprofile{
    position: absolute;
    top: 65%;
    background: #F0F0F0;
    left: 3%;
    width: 39%;
    overflow-y: scroll;
    /* height: 33vh; */
    height: auto;
    /* min-height: 19vh; */
    flex-direction: column;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}
.showprofessiondivinprofile::-webkit-scrollbar {display: none;}
/* show number as for country code End here  */