.popovermaindiv {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* padding: 4%; */
  /* width: 52%;
  margin-left: 30%; */
}
.popoveralldataofuseraccess {
  padding: 6%;
}
.admindropdowndiv {
  margin-top: 5%;
}
.pocdropdowndiv {
  margin-top: 5%;
}
.userdropdowndiv {
  /* padding: 5%; */
  margin-top: 5%;
}
.admindiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.admindivimageandnamediv {
  display: flex;
  width: 46%;
  justify-content: space-between;
}
.admindivimageandnamediv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-right: 8%;
  color: #1b4d9f;
}
.admindivimage {
  background: #1b4d9f;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  padding-top: 5%;
}
.admindivimage img {
  height: 2vh;
}

.admindropdownmaindiv {
  /* padding-top: 7%; */
  background: #f7f7f7;
  margin-top: 6%;
  padding-left: 6%;
  padding-right: 6%;
}

.adminsearchproject {
  display: flex;
  align-items: center;
  background: #d9d9d9;
}
.adminsearchproject img {
  height: 12px;
  margin-right: 4%;
  padding-left: 5%;

  margin-top: 2%;
}
.admininput {
  width: 100%;
  background: #d9d9d9;
}
.adminul {
  overflow-y: scroll;
  max-height: 20vh;
}
.adminul::-webkit-scrollbar {
  display: none;
}
.admindatadiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4%;
}
.adminprojectlist {
  font-family: Inter;
  font-style: normal;

  font-weight: 400;
  font-size: 14px;

  color: #6e6e6e;
}
.pocdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.pocdivimageandnamediv {
  display: flex;
  width: 37%;
  justify-content: space-between;
}
.pocdivimageandnamediv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: green;
}

.pocdivimage {
  background: green;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  padding-top: 5%;
}
.pocdivimage img {
  height: 2vh;
}
.userdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.userdivimageandnamediv {
  display: flex;
  width: 37%;
  justify-content: space-between;
}

.userdivimageandnamediv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: #92c5ea;
}
.userroledivincompany {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4%;
}
.tableinnerlistofdata {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6e6e6e;
}

.userdivimage {
  background: #92c5ea;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  padding-top: 5%;
}

.userdivimage img {
  height: 16px;
}

::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #6e6e6e;
}

.innerdataoftable {
  /* style={{ color: 'black ', fontSize: '1.4rem', marginTop: '1%' }} */
  color: midnightblue;
  font-size: 1rem;
  margin-top: 4%;
}

.submitbtn {
  border: none;
  background: #1b4d9f;
  border-radius: 16.6354px;
  width: auto;
  margin-top: 6%;
  margin-left: 30%;
  /* margin-bottom: 6%; */
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitbtn span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  color: #ffffff;
  padding: 0%;
}
