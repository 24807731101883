.closeprojectmodalbody{
    height: 25vh;
    background: #F4F4F4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 72%;
}
.closeprojectbtns{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 4%;
}
.closeprojectword{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}
.closeprojectdescription{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 143.02%;
    text-align: center;
    margin-top: 2%;
    color: #000000;
    width: 90%;
}
.closebtncloseproject{
    background: #FF3131;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11.6518px;
    padding: 1%;
    line-height: 25px;
    color: #FFFFFF;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12%;
}
.canclebtncloseproject{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 25px;
    color: #383838;
    width: 25%;
    margin-left: 15%;
}