.movemodalmaindiv{
    background: #FFFFFF;
box-shadow: 0px 1.46936px 1.46936px rgba(0, 0, 0, 0.25);
border-radius: 10px;
/* border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px; */
height:35vh ;
 background:white;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.movemodalmaindiv img{
    height: 7vh;
}

.movemodalspan{
    width: 66%;
    /* margin-left: 10%; */
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #787878;

}
.movemodalbtn{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 4%;
}
.movemodalmovespan{
    margin-bottom: 2%;
    margin-top: 2%;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
/* identical to box height */


color: #383838;
}
.movecancle{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18.6518px;
line-height: 25px;
/* identical to box height */


color: #383838;
}
.moveconfrom{
    background: #1B4D9F;
    border-radius: 10px;
    height: 5vh;
    width: 30%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    display: flex;
    font-size: 14.6518px;
    line-height: 25px;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;

}