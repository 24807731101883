
.movequerymaindiv{
    /* width: auto; */
    background: #FFFFFF;
box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
/* height: 29vh; */
height: auto;
/* width: 47%; */
width: 100%;
/* margin-left: 17%; */
margin-top: 3%;
border-radius: 20px;

}
.movequerytosequencediv{
    display: flex;
    align-items: center;
    border-radius: 15px;
    /* justify-content: space-around; */
    padding: 6px;
    width: 100%;
    color: #142B59;
}
.movequerytosequencediv:hover {
    background: #194895;
    color: #FFFFFF;
  
}
.movequerytosequencediv:hover .movequerydots{
    background: #FFFFFF;
}
.moveicon{
    height: 18px;
    margin-right: 6%;
    margin-left: 3%;
}
.movequerydots{
    background: #142b59;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    margin-right: 6%;
    margin-left: 5%;
}
.scrollmove{
    overflow-y: scroll;
    max-height: 21vh;
    height: auto;
}
.scrollmove::-webkit-scrollbar {display: none;}
.movetosequencename{
 
    width: 85%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* padding: 8px; */
    /* color: #142B59; */
}
.movequerycheckbox{
    width: 15%;
}
.moveto{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 8px;
    color: #194895;
}
.horizontalline{
    height: 2px;
    border: 1px solid #80808066;
    width:100%;
    margin-top:0%;
    margin-bottom:0%;
}
.movequerybtn{

    width: 27%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30%;
    margin-top: 4%;
    background: #1B4D9F;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12.1993px;
    padding: 2%;
    line-height: 15px;
    color: #FFFFFF;
    border-radius: 16.6354px;

}

.css-1dmzujt{
    overflow: inherit !important;
}