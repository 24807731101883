.descriptionmaindiv{
    background: #F4F4F4;
    border-radius: 20px;
    width: 66%;
    height: 90vh;
    margin-top: 2%;
}
.descriptionheader {
    height: 7vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: #194895;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
}

.descriptionprojectuser{
    display: flex;
    align-items: center;
    margin-right: 4%width;
    /* margin-right: 4%; */
    width: 17%;
}
.descriptionprojectuser img{
    height: 12px;
    margin-right: 2%;
}
.descriptionprojectname{
    display: flex;
    align-items: center;
    /* margin-right: 4%; */
    width: 78%;
    margin-left: 2%;
}
.descriptionprojectname img{
    height: 15px;
    margin-right: 4%;
}

/* start */

.projectdescriptioninfo{
    display: flex;
    flex-direction: column;
    padding-left: 0%;
    /* height: 12vh; */
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
   
}
.projectinfo{
    display: flex;
    align-items: center;
    padding-left: 2%;
}
.projectinfo img{
 height: 13px;
    margin-top: .6%;
}
.projectinfo span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    color: #142B59;
    margin-left: 1%;
}

.projectcreatedbyinfo{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    padding-left: 2%;
    color: #7E7E7E;
    padding-top: .5%;
}
.projectdescriptionhr{
    border: 1px solid #8080806b;
    margin-top: 0%;
    margin-bottom: 0%;
    width: 94%;
    margin-left: 3%;
}

.projectsquares{
    height: 32%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    /* background: red; */
}
.projectopensquareblue{
    background: #194895;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 4%; */
    height: 45%;
}

.projectopensquarewhite{
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 4%; */
    height: 45%;
}

.projectsequencesquare{
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 4%; */
    height: 45%;
}
.projectqueryopensquare{
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 4%; */
    height: 45%;
}
.projectclosequerysquare{
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 4%; */
    height: 45%;
}
.totaluserinprojectmaindiv{
    margin-top: 2%;
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    width: 88%;
    height: 11vh;
    margin-left: 6%;
}


.arrowstatus{
    color: #ffffff;
}
.arrowstatus{
    color: #142B59;;
}
.imagediv{
width:25%;
height:64%;
background: #FFFFFF;
box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
border-radius: 15px;
display: flex;
align-items: center;
justify-content: center;
}
.imagedivblue{
    width:25%;
    height:64%;
    background: #EAF2FF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .imagedivblue img{
        height:30px
    }
.imagediv img{
    height:30px
}
.optionspan{
    width:58%;
height:64%;
/* background: #FFFFFF; */
}
.isprojectopen{
    display: flex;
    align-items: center;
}
.isprojectopendotgreen{
    height: 8px;
    width: 8px;
    background: green;
    margin-right: 5%;
    margin-right: 9%;
    margin-left: 11%;
    border-radius: 50%;
}
.isprojectopendotred{
    height: 8px;
    width: 8px;
    background: red;
    margin-right: 5%;
    margin-right: 9%;
    margin-left: 11%;
    border-radius: 50%;
}
.isopentrue{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 27px;
/* identical to box height */


color: #FFFFFF;

}
.closequeryspan{
    color: red;
}
.isselectedfalse{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 27px;
color: #194895;

}
.closeprojectuserbtn{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
margin-right: 7%;
color: #7E7E7E;
}
.isopenfalse{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 27px;
    /* identical to box height */
    
    
    color: #7E7E7E;

}
.closeproject{
    display: flex;
}
 .white{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 9%;
    /* line-height: 19px; */
    color: #FFFFFF;
}
.blue{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 9%;
    /* line-height: 19px; */
    color: #142B59;
}


.optionspans{
    margin-left: 4%;
}
.total{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    
    color: #252525;
}
.projectsquarespans{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    
    color: #7E7E7E;
}
.projectsquarespansclosed{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #7E7E7E;
}
.projectsquarespansclosed span{
    color: #DF6B6B;
}
.projectsquarespans span{
color: #19950F;
 }
.projectusers{
    height: auto;
    width: 95%;
    max-height: 36%;
    overflow-y: scroll;
    /* background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2); */
    border-radius: 25px;
    margin-top: 2%;
    margin-left: 2.5%;
}
/* .projectusers::-webkit-scrollbar {display: none;} */
.projectusers::-webkit-scrollbar {
    width: .4rem;
  }
  
  .projectusers::-webkit-scrollbar-track {
    background-color: white;
    border: 14px solid transparent;
    background-clip: content-box;
    border-radius: 100vw;
    /* margin-block: 3rem; */
  }
  
  .projectusers::-webkit-scrollbar-thumb {

    border-radius: 1vw;
    background: #1B4D9F;
    width: 1px;
    height: 10px;
  }
.projectlistuser{
    display: flex;
    margin-left: 2%;
    margin-top: 2%;
    align-items: center;
    justify-content: space-between;
    /* some changes */
}
.showstatus{
    display: flex;
    align-items: center;
    position: absolute;
    top: 39%;
    left: 42%;
    width: 11%;
    background: #194895;
    padding: 0%;
    border-radius: 10px;
    padding: 6px;
}
.showstatus span{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 27px;

color: #FFFFFF;
}
.greendot{
    height: 8px;
    width: 8px;
    margin-right: 6%;
    background: green;
    border-radius: 50%;
}
.reddot{
    height: 8px;
    width: 8px;
    margin-right: 6%;
    background: red;
    border-radius: 50%;
}
.listofadminandsimpleuser{
        display: flex;
    width: 61%;
    align-items: center;
}
.userlistnameinprojectdescription{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #142B59;
    display: flex;
    padding-left: 5%;
    width: 78%;
}
.userlistnameinprojectdescription span{
    color: 
    #8E8E8E;
}

/* close project description design */

.closeprojectdescriptionheader {
    height: 7vh;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: #DF5959;;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
}

.closeprojectimagediv{
    width:25%;
height:64%;
background: #FBE1E1;
box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
border-radius: 15px;
display: flex;
align-items: center;
justify-content: center;
}
.closeprojectimagediv img{
    height:30px
}
.closeprojecttotal{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;

color: #7E7E7E;
}

.pleasereopenprojecthover{
    position: absolute;
    top: 35%;
    background-color: #DF5959;
    color: white;
    font-size: 15px;
    padding: 1%;
    border-radius: 12px;
}
