.animatedLogindiv {
    /* width:70%; */
    height: 100vh;
    /* margin-left: 12%; */
    background-color: #ffffff;
}

.animatedsmalldiv {
    /* margin-left: 12%; */
    /* margin-top: 0%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.animatedimagepasword {
    height: 3vh;

}

.animatedimage {
    height: 8rem;
    /* margin-top: 15%;
    margin-left: 31%; */
    margin-bottom: 3%;
}

.animatedLineargradientdiv {
    height: 100vh;
    width: 100%;

}

.animatedh2design {
    color: #142B59;
    font-style: bold;
    /* margin-left: 27%; */
    margin-top: 3%;
    font-weight: 700;
}

.animatedinputFile {
    background-color: #F0F0F0;
    height: 6vh;
    margin-left: 18%;
    width: 25vw;
    margin-top: 3%;
    border-radius: 10px;
    border: none
}

.animatedforgetpassword {
    margin-top: 2%;
    /* margin-left: 27%; */
    color: #1B4D9F;
}

.animatedforgetpassword span {
    /* font-family: 'Aspira'; */
    font-style: normal;
    font-weight: 450;
    font-size: 1rem;
    line-height: 28px;
    color: #1B4D9F;
    cursor: pointer;
}

.animatedloginbtn {
    margin-top: 2%;
    width: 23%;
    height: 5vh !important;
    /* margin-left: 28%; */
    background-color: #1B4D9F;
    border: none;
    border-radius: 5rem;
}

.animatedloginbtn span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #FFFFFF;
}

.animatedlineargradientminidiv {
    position: relative;
    height: 100vh;
}

.animatedbuilding {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
}

.animatedhellospan {
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    top: 15%;
    left: 34%;
    font-size: xxx-large;
    font-weight: 500;
    font-size: 2rem;
}

.animateddetailspan {
    color: #FFFFFF;
    position: absolute;
    left: 31.54%;
    top: 27.23%;
    width: auto;
    bottom: 57.13%;
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 151.52%; */
    text-align: center;
}

.animatednamediv {
    height: 8vh;
    width: 52%;
    display: flex;
    align-items: center;
    margin-left: 7%;
    justify-items: center;
}

.animatedemailspan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    display: flex;
    border-radius: 0%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #F0F0F0;

}

.animatednameimage {
    height: 2vh;
}

.animatednameinput1 {
    height: 6vh;
    width: 90%;
    border: none;
    /* border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; */
    background-color: #F0F0F0;

}

.animatedloginform {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.animatedjourneyspan {
    color: #FFFFFF;
    position: absolute;
    left: 39.54%;
    top: 31.23%;
    bottom: 57.13%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 151.52%;
    text-align: center;
}

.animatedsignupbtn {
    position: absolute;
    top: 45%;
    left: 37%;
    /* background-image: linear-gradient(#102756, #1B4D9F); */
    background: transparent;
    border: 1px solid white;
    width: 26%;
    border-radius: 28px;
    border-color: #FFFFFF;
    height: 6vh;

}

@media (max-width: 768px) {

    .Lineargradientdiv {
        /* width:30%; */
        height: 100vh;
        background-color: #22c049;
    }

}



/* signup css start here */

.animatedleftdivmain {
    background-image: linear-gradient(#102756, #1B4D9F);
    height: 100vh;
    width: 100%
}

.animatedrightdivmain {
    background-color: #ffffff;
    height: 100vh;
    width: 100%
}

.animatedcreateAccount {
    position: relative;
    width: 100%;
    height: 20vh;
}

.animatedcreateAccountspan {
    position: absolute;
    top: 41%;
    left: 29%;
    font-size: xx-large;
    font-weight: 700;
    color: #142B59;
    font-family: 'Inter';
    font-style: normal;
}

.animatedformdiv {
    height: 80vh;
    width: 100%;

}

.animatedform::-webkit-scrollbar {
    display: none
}

.animatedmainnumberselectclass {
    display: flex;
    width: 100%;
    margin-bottom: 1vh;
}

.animatedselectdropdown {
    display: block;
    border: none;
    background-color: #F0F0F0;
    height: 6vh;
    /* width: 119%; */
    cursor: pointer;
    width: 4vw;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.animatedselectdropdown span {
    font-size: 11px;
    font-weight: 600;
}

.animatedselectedinput {
    height: 6vh;
    width: 15.4vw;
    border: none;
    background-color: #F0F0F0;
    margin-left: -4%;

}

.animatednameinput1:focus {
    background: #F0F0F0;
}

.animatednameinput1::placeholder {

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 29px;

    color: #868686;
}

.animatedselectedinputcountry {
    height: 6vh;
    width: 12.4vw;
    border: none;
    background-color: #F0F0F0;
    margin-left: -4%;
}

.animatedselectedinput:focus {
    background-color: #F0F0F0;
}

.animatedselectdropdown:focus-visible {
    border: none !important;
    outline: none;
}

.animatedselectedinputdiv {
    border: none;
    outline: none;
    width: 85%;
    margin-left: 1%;
    height: 5vh;
    display: flex;
}

.animatedselectedinputdiv input {
    border: none;
    outline: none;
    background-color: #F0F0F0;
    width: 55%;
    height: 6vh;
    border-radius: 0%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.animatedselectedinputdiv input:focus {

    background-color: #F0F0F0;

}


.animatedform {
    position: relative;
    margin-left: 30%;
    height: 80vh;
    overflow: auto;
}

.animatednamediv1 {
    height: 8vh;
    width: 67%;
    display: flex;
    align-items: center;
}

.animatedname {
    height: 8vh;
    width: 71%;
    margin-top: 2%;
    /* background:  #F0F0F0; */
    /* outline-color: rgb(9, 9, 80); */
}

.animatedtouchname {

    height: 8vh;
    width: 54%;
    margin-top: 2%;
    outline-color: rgb(9, 9, 80);

}

.animatednamespan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    background-color: #F0F0F0;
}

.animatedemailimage {
    height: 11px
}

.animatednameimage {
    height: 14px;
}

.animatednameinput {
    height: 6vh;
    width: 100%;
    border: none;
    background: #F0F0F0;
    border-radius: 0%;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;

}

.animatednameinput:focus {
    background-color: #F0F0F0;
}

/* .emailspan {
    height: 6vh;
    width: 2.2rem;
    border: none;
    background-color: #F0F0F0;

} */

.animatedselectcountry {
    display: flex;
    margin-bottom: 3vh;
}

.animatedselectcountryspan {
    width: 21%;
    BACKGROUND-COLOR: #F0F0F0;
    height: 5vh;
}

.animatedselectcountryspan span {
    padding: 4%;
}

.animatedselectCountryvalue {
    width: 34%;
    height: 3vh;
    border: none;
    outline: none;
}

.animatedphoneInput {
    height: 8vh;
    width: 73%;
    border: none
}

.animatednamedivbtn {
    height: 8vh;
    width: 54%;
    margin-top: 4%;
}

.animatedsignbtn {
    margin-top: -1%;
    width: 55%;
    height: 6vh;
    margin-left: 30%;
    background-color: #1B4D9F;
    border: none;
    border-radius: 20rem;
}

.css-13cymwt-control {
    border: none;
    outline: none !important;
}

.animatedLineargradientdiv {
    height: 100vh;
    /* background-image: linear-gradient(#102756, #1B4D9F); */
}

.animatedlineargradientminidiv {
    position: relative;
    height: 100vh;
}

.animatedbuilding {
    height: 28vh;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.animatedfinalLogo {
    height: 16vh;
    /* margin-left: 45%; */
    position: absolute;
    left: 40%;
    top: 6%;
}

.nameimage {
    height: 2vh;
}

.animatedhellospan1 {
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    /* position: absolute; */
    /* top: 27%;
    left: 30%; */
    margin-top: 5%;
    margin-bottom: 2%;
    font-size: xxx-large;
    font-weight: 500;
    font-size: 2rem;

}

.animateddetailspan1 {
    color: #FFFFFF;
    /* position: absolute;
    left: 27.54%;
    top: 37.23%;
    bottom: 57.13%; */
    width: 50%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2;
    margin-bottom: 2%;
    text-align: center;
}

.animatedspandiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 2px solid white; */
    height: 70%;
}

.animatedjourneyspan1 {
    color: #FFFFFF;
    /* position: absolute;
    left: 31.54%;
    top: 41.23%;
    bottom: 57.13%; */
    font-weight: 400;
    /* font-size: 1rem; */

    text-align: center;
}

.animatedloginbtn1 {
    /* position: absolute;
    top: 55%;
    left: 34%; */
    /* background-image: linear-gradient(#102756, #1B4D9F); */
    width: 28%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 28px;
    border-color: #FFFFFF;
    height: 6vh;
    margin-top: 3%;

}

.animatedloginbtn1 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

.Errors {
    color: red;
    font-size: xx-small;
    /* margin-top:-3%; */

}

.shownumberdiv {
    position: absolute;
    top: 32%;
    background: #F0F0F0;
    left: 0%;
    width: 67%;
    flex-direction: column;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
}

.shownumberminidiv {
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
}

.shownumberminidiv:hover {
    background: #d8d8d8;
}
.Scroll_DIv{
    overflow: hidden;
}
.showcountrydiv {
    height: 25vh ;
    position: absolute;
    top: 75%;  
    background: #F0F0F0;
    left: 0%;
    width: 67%;
    overflow-y: scroll;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    justify-content: center;
    flex-direction: column;
}
.showcountrydiv::-webkit-scrollbar {display: none;}
.showcountryminidiv {
    padding: 3px;
    font-size: 14px;
    font-weight: 600;
}

.showcountryminidiv:hover {
    background: #d8d8d8;
}